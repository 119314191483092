import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, ConvertToInt } from '../../../utility/Number';

// Modely
import { PriceGroup, PriceGroupPriceList, PriceGroupPriceListInputs, PriceGroupPriceListMethods, PriceGroupRoundingAccuracies, PriceGroupRoundingMethods, PriceList } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControlLabel, Switch, TableCell, TableRow, Table, TableBody, TableHead, Accordion, AccordionDetails, AccordionSummary, Typography, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Confirm, { ConfirmProps } from '../../../components/Confirm';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import SortIcon from '@mui/icons-material/Sort';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PercentIcon from '@mui/icons-material/Percent';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): PriceGroup => ({
    id: 0,
    default: false,
    actived: true,
    ordinalNumber: 0,
    name: '',
    note: '',
    priceLists: []
});

// Vstupné parametre
export interface PriceGroupCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const PriceGroupCreate = (props: PriceGroupCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<PriceGroup>(EmptySource());
    const priceListBasic: PriceList = { id: 0, name: 'Základná cena' };
    const [priceLists, setPriceLists] = useState<PriceList[]>([priceListBasic]);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Načítam zoznam cenníkov
    const loadPriceLists = () => {
        axios
            .get(AppConfig.ApiUri + 'pricelist')
            .then(response => {
                setPriceLists([priceListBasic, ...response.data.filter((pricelist: PriceList) => pricelist.actived)]);
            });
    };
    useEffect(() => loadPriceLists(), []);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'pricegroup/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as PriceGroup);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangePriceList = useCallback((id: number, property: string, value: any) => {
        setSource(prev => {
            const item: PriceGroupPriceList = { ...prev?.priceLists?.find(item => item.priceListId === id), priceListId: id, [property]: value };

            // Metóda výpočtu "zľava" je povolená len pre základnú cenu (zruším pri zmene na iný vstup)
            if (property === 'priceInput' && item.priceInput !== PriceGroupPriceListInputs.PriceSale && item.priceMethod === PriceGroupPriceListMethods.Discount) {
                item.priceMethod = PriceGroupPriceListMethods.Markup;
            }
            // Pre základnú cenu je povolená len metóda "zľava"
            if (property === 'priceInput' && item.priceInput === PriceGroupPriceListInputs.PriceSale) {
                item.priceMethod = PriceGroupPriceListMethods.Discount;
            }

            const next = { ...prev, priceLists: [...prev?.priceLists?.filter(item => item.priceListId !== id) ?? [], item] };
            return next;
        });
    }, []);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        // Marža musí byť menej ako 100%, ak nájdem chybný záznam, tak nepokračujem
        if (source.priceLists?.find(priceList => priceList.priceMethod === PriceGroupPriceListMethods.Margin && (priceList.priceValue ?? 0) >= 100) !== undefined) {
            setWarning({ ...EmptyWarning, text: 'Chyba! Marža musí byť menšia ako 100%!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'pricegroup', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Cenová skupina' : 'Nová cenová skupina')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} md={3} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna" />
                            </Grid>

                            <Grid item xs={12} md={3} mb={1}>
                                <FormControlLabel control={<Switch checked={source.default} name="default" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Predvolená skupina" />
                            </Grid>

                            <Grid item xs={12} sm={9}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Výpočet predajnej ceny</Typography>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <PriceListTable onChangePriceList={handleChangePriceList} priceLists={priceLists} priceGroupPriceList={source.priceLists} />
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="info">
                                    Produkty môžu byť zaradené do svojej cenovej skupiny, podľa ktorej je možné pri príjme na sklad automaticky vypočítať novú predajnú cenu.
                                    Pokiaľ produkt nemá nastavenú žiadnu cenovú skupinu, tak je použitá predvolená cenová skupina (ak existuje).<br /><br />
                                    <u>Marža</u> - percentuálny rozdiel medzi vstupnou a výstupnou cenou.<br />
                                    <u>Prirážka</u> - percentuálne zvýšenie vstupnej ceny pre stanovenie výstupnej ceny.<br />
                                    <u>Zľava</u> - percentuálne zníženie vstupnej ceny.<br /><br />
                                    <u>Priemerná skladová cena (VNC)</u> - vážený priemer skladových cien (VNC) na všetkých skladoch.<br />
                                    <u>Nákupná cena</u> - aktuálna nákupná cena skladovej karty.<br />
                                    <u>Priemerná nákupná cena</u> - vážený priemer posledných nákupných cien na všetkých skladoch.<br />
                                    <u>Posledná nákupná cena</u> - posledná nákupná cena z akéhokoľvek skladu.<br />
                                </Alert>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />
        </>
    )
}

export default PriceGroupCreate;

interface PriceListTableProps {
    priceLists?: PriceList[];
    priceGroupPriceList?: PriceGroupPriceList[];
    onChangePriceList: (id: number, property: string, value: any) => void;
}

const PriceListTable = React.memo((props: PriceListTableProps) => {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ width: '30%' }}>Cenník</TableCell>
                    <TableCell>Vstupná cena</TableCell>
                    <TableCell>Výpočet</TableCell>
                    <TableCell>Hodnota %</TableCell>
                    <TableCell>Zaokrúhlenie</TableCell>
                    <TableCell>Metóda</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.priceLists?.map((pricelist, index) => {

                    let item = props.priceGroupPriceList?.find(item => item.priceListId === pricelist.id);
                    let itemPriceInput = item?.priceInput ?? 0;
                    let itemPriceValue = item?.priceValue ?? 0;
                    let itemPriceMethod = item?.priceMethod ?? 0;
                    let itemRoundingMethod = item?.roundingMethod ?? 0;
                    let itemRoundingAccurancy = item?.roundingAccurancy ?? 0;

                    return (
                        <TableRow
                            key={pricelist.id}
                            sx={{ 'td': { padding: .2 }, '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <strong>{pricelist.name}</strong>
                            </TableCell>
                            <TableCell>
                                <Select fullWidth sx={{ width: 170 }} size="small" variant="outlined" value={itemPriceInput} name="priceInput"
                                    onChange={(e) => { props.onChangePriceList((pricelist?.id ?? 0), e.target.name, e.target.value); }}>
                                    <MenuItem value={PriceGroupPriceListInputs.Disabled}>Vypnuté</MenuItem>
                                    {(pricelist?.id ?? 0) > 0 && <MenuItem value={PriceGroupPriceListInputs.PriceSale}>Základná cena</MenuItem>} {/* Pri cenníku "základná cena" nemôže byť aj ako vstupná cena */}
                                    <MenuItem value={PriceGroupPriceListInputs.PriceWac}>Priemerná skladová cena (VNC)</MenuItem>
                                    <MenuItem value={PriceGroupPriceListInputs.PricePurchase}>Nákupná cena</MenuItem>
                                    <MenuItem value={PriceGroupPriceListInputs.PricePurchaseAverage}>Priemerná nákupná cena</MenuItem>
                                    <MenuItem value={PriceGroupPriceListInputs.PricePurchaseLast}>Posledná nákupná cena</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select fullWidth sx={{ width: 100 }} size="small" variant="outlined" value={itemPriceMethod} name="priceMethod"
                                    disabled={itemPriceInput === 0}
                                    onChange={(e) => { props.onChangePriceList((pricelist?.id ?? 0), e.target.name, e.target.value); }}>
                                    {(itemPriceInput !== PriceGroupPriceListInputs.PriceSale) && <MenuItem value={PriceGroupPriceListMethods.Markup}>Prirážka</MenuItem>}
                                    {(itemPriceInput !== PriceGroupPriceListInputs.PriceSale) && <MenuItem value={PriceGroupPriceListMethods.Margin}>Marža</MenuItem>}
                                    {(itemPriceInput === PriceGroupPriceListInputs.PriceSale) && <MenuItem value={PriceGroupPriceListMethods.Discount}>Rabat (zľava)</MenuItem>}
                                </Select>
                            </TableCell>
                            <TableCell>
                                <TextField fullWidth sx={{ width: 100 }} size="small" name="priceValue" type="text" variant="outlined" autoComplete="off"
                                    required={itemPriceInput > 0} disabled={itemPriceInput === 0}
                                    value={itemPriceValue}
                                    onChange={(e) => { props.onChangePriceList((pricelist?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PercentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </TableCell>
                            <TableCell>
                                <Select fullWidth sx={{ width: 170 }} size="small" variant="outlined" value={itemRoundingAccurancy} name="roundingAccurancy"
                                    disabled={itemPriceInput === 0}
                                    onChange={(e) => { props.onChangePriceList((pricelist?.id ?? 0), e.target.name, e.target.value) }}>
                                    <MenuItem value={PriceGroupRoundingAccuracies.None}>Bez zaokrúhlenia (4 desatinné miesta)</MenuItem>
                                    <MenuItem value={PriceGroupRoundingAccuracies.WholeHundreds}>Celé stovky jednotky meny (100 EUR)</MenuItem>
                                    <MenuItem value={PriceGroupRoundingAccuracies.WholeTens}>Celé desiatky jednotky meny (120 EUR)</MenuItem>
                                    <MenuItem value={PriceGroupRoundingAccuracies.WholeUnits}>Celé jednotky meny (2 EUR)</MenuItem>
                                    <MenuItem value={PriceGroupRoundingAccuracies.Tenths}>Desatiny jednotky meny (1,9 EUR)</MenuItem>
                                    <MenuItem value={PriceGroupRoundingAccuracies.Hundredths}>Stotiny jednotky meny (1,99 EUR)</MenuItem>
                                    <MenuItem value={PriceGroupRoundingAccuracies.HundredthsStep5}>Stotiny po 5 stotín jednotky meny (1,95 EUR)</MenuItem>
                                    <MenuItem value={PriceGroupRoundingAccuracies.Thousandths}>Tisíciny jednotky meny (1,999 EUR)</MenuItem>
                                    <MenuItem value={PriceGroupRoundingAccuracies.ThousandthsStep5}>Tisíciny po 5 tisícin jednotky meny (1,995 EUR)</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select fullWidth sx={{ width: 130 }} size="small" variant="outlined" value={itemRoundingMethod} name="roundingMethod"
                                    disabled={itemPriceInput === 0 || itemRoundingAccurancy === 0}
                                    onChange={(e) => { props.onChangePriceList((pricelist?.id ?? 0), e.target.name, e.target.value); }}>
                                    <MenuItem value={PriceGroupRoundingMethods.Mathematical}>Matematicky</MenuItem>
                                    <MenuItem value={PriceGroupRoundingMethods.Up}>Nahor</MenuItem>
                                    <MenuItem value={PriceGroupRoundingMethods.Down}>Nadol</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
});
