import React, { useContext, useEffect, useState } from 'react';
import { AppRouteUrl } from '../../AppRoutes';
import { AppConfig } from '../../AppConfig';
import { AppContext } from '../../AppContext';
import axios from 'axios';

// Modely
import { Document, DocumentType, DocumentLite, DocumentRelated, DocumentRelatedLoad, DocumentCopy } from '../../models/Models';
import { DocumentCreateComponentProps } from './DocumentCreate';

// Komponenty
import { Button, Grid, IconButton, ListItemIcon, ListSubheader, Menu, MenuItem, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import DocumentsDialog, { DocumentsDialogProps } from './DocumentsDialog';
import DocumentCreate, { DocumentCreateProps } from './DocumentCreate';
import ReportDocument, { ReportDocumentProps } from '../report/ReportDocument';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

// Pomocné parametre pre callback výberu dokladov
/*
interface DocumentsDialogArgument {
    parentList?: boolean;
    documentType?: DocumentType;
}
*/

// Vstupné parametre
export interface DocumentCreateRelatedProps extends DocumentCreateComponentProps {
    documentTypes: DocumentType[];
}

const DocumentCreateRelated = (props: DocumentCreateRelatedProps) => {

    // Globálne premenné
    const appContext = useContext(AppContext);

    // Stav
    const [addDocumentEl, setAddDocumentEl] = useState<null | HTMLElement>(null);
    const [addDocumentGenerate, setAddDocumentGenerate] = useState<boolean>(false);
    const [addDocumentParentList, setAddDocumentParentList] = useState<boolean>(false);
    const [addDocumentDocumentType, setAddDocumentDocumentType] = useState<DocumentType>();
    const [addDocumentToLoad, setAddDocumentToLoad] = useState<number | DocumentLite[] | undefined>(undefined);

    // Automatické vloženie dokladu do zoznamu po výbere (zoznam) alebo vytvorení (id)
    useEffect(() => {
        if (addDocumentToLoad === undefined) {
            return;
        }
        if (typeof addDocumentToLoad === 'number') {
            // Po vygenerovaní nového dokladu ho musím načítať, keďže číslo dokladu sa generuje až po uložení
            axios
                .get(AppConfig.ApiUri + 'document/' + (addDocumentToLoad as number))
                .then(response => {
                    if (response.data !== null) {
                        var document = response.data as Document;
                        props.setSource(prev => {
                            const changed: DocumentRelated[] = [...(addDocumentParentList === true ? prev.relatedParents : prev.relatedChildren) ?? []];
                            if (changed.find(item => item.id === document.id) === undefined) {
                                changed.push(DocumentRelatedLoad(document, document.documentTypeId ?? 0, props.documentTypes.find(type => type.id === document.documentTypeId)?.other?.translationName ?? ''));
                            }
                            if (addDocumentParentList === true) {
                                return ({ ...prev, relatedParents: changed });
                            }
                            else {
                                return ({ ...prev, relatedChildren: changed });
                            }
                        });
                    }
                });
        }
        else {
            // Vložím označečné položky zo zoznamu
            let documents = addDocumentToLoad as DocumentLite[];
            if (documents.length > 0) {
                props.setSource(prev => {
                    const changed: DocumentRelated[] = [...(addDocumentParentList === true ? prev.relatedParents : prev.relatedChildren) ?? []];
                    documents.forEach(document => {
                        if (changed.find(item => item.id === document.id) === undefined && document.id !== props.source.id) {
                            changed.push(DocumentRelatedLoad(document, addDocumentDocumentType?.id ?? 0, addDocumentDocumentType?.other?.translationName ?? ''));
                        }
                    });
                    return ({ ...prev, ...(addDocumentParentList === true ? { relatedParents: changed } : { relatedChildren: changed }) });
                });
            }
        }
        setAddDocumentToLoad(undefined);
    }, [addDocumentToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    // Zoznam dokladov    
    const [documentsDialog, setDocumentsDialog] = useState<DocumentsDialogProps>({
        open: false,
        keepMounted: false,
        onSelect: (documents: DocumentLite[]) => {
            setAddDocumentToLoad(documents);
            handleDocumentsClose();
        },
        onClose: () => handleDocumentsClose()
    });

    // Zobrazenie dokladov
    const handleDocumentsOpen = (documentType: DocumentType) => {
        setDocumentsDialog(prev => ({ ...prev, type: documentType.id ?? 0, open: true }));
    };
    const handleDocumentsClose = () => {
        setDocumentsDialog(prev => ({ ...prev, open: false }));
    };

    // Vybrať doklad
    const handleSelectDocument = (documentType: DocumentType) => {
        setAddDocumentDocumentType(documentType);
        handleDocumentsOpen(documentType);
    };

    // Nový doklad
    const [documentCreate, setDocumentCreate] = useState<DocumentCreateProps>({
        documentType: {},
        documentTypes: [],
        open: false,
        keepMounted: true,
        disableSaveAction: true,
        onSave: (id?: number) => {
            if ((id ?? 0) > 0) {
                setAddDocumentToLoad(id ?? 0);
            }
        },
        onClose: () => setDocumentCreate(prev => ({ ...prev, open: false }))
    });

    // Generovať nový doklad doklad
    const handleGenerateDocument = (documentType: DocumentType) => {
        // Vygenerujem kópiu dokladu
        let document = DocumentCopy({
            documents: [props.source],
            documentsTargetType: documentType,
            documentsAreParents: addDocumentParentList,
            documentTypes: props.documentTypes,
            userName: appContext.userName ?? ''
        });

        // Zobrazím formulár
        setDocumentCreate(prev => ({
            ...prev,
            open: true,
            document: document,
            documentType: documentType,
            documentTypes: props.documentTypes
        }));
    };

    // Tlač dokladu
    const [documentPrint, setReportDocument] = useState<ReportDocumentProps>({
        documentId: 0,
        documentTypeId: 0,
        open: false,
        preview: true,
        disableHtml: true,
        keepMounted: true,
        onClose: () => setReportDocument(prev => ({ ...prev, open: false }))
    });

    // Vygenerovať nový alebo stiahnuť nahraný doklad
    const handlePrint = (id: number, typeId: number) => {
        setReportDocument(prev => ({ ...prev, documentId: id, documentTypeId: typeId, open: true }));
    };

    return (
        <>
            <Menu id="menu-adddocument" anchorEl={addDocumentEl} open={Boolean(addDocumentEl)} onClose={() => setAddDocumentEl(null)}>
                {props.documentTypes.find(item => item.sale === true) !== undefined && <ListSubheader component="div" disableSticky sx={{ maxHeight: '20px', lineHeight: '20px', background: 'none', fontSize: 'small' }}>Predaj</ListSubheader>}
                {props.documentTypes.filter(item => item.sale === true)?.map((item, index) =>
                    <MenuItem key={item.id ?? 0} onClick={() => { if (addDocumentGenerate) { handleGenerateDocument(item); } else { handleSelectDocument(item); } setAddDocumentEl(null); }}><ListItemIcon><DescriptionIcon fontSize="small" /></ListItemIcon> {item.other?.translationName}</MenuItem>
                )}
                {props.documentTypes.find(item => item.sale === false) !== undefined && <ListSubheader component="div" disableSticky sx={{ maxHeight: '20px', lineHeight: '20px', background: 'none', fontSize: 'small' }}>Nákup</ListSubheader>}
                {props.documentTypes.filter(item => item.sale === false)?.map((item, index) =>
                    <MenuItem key={item.id ?? 0} onClick={() => { if (addDocumentGenerate) { handleGenerateDocument(item); } else { handleSelectDocument(item); } setAddDocumentEl(null); }}><ListItemIcon><DescriptionOutlinedIcon fontSize="small" /></ListItemIcon> {item.other?.translationName}</MenuItem>
                )}
            </Menu>

            <Grid container columnSpacing={1}>

                {/* Predchádzajúce doklady  */}
                {/* *************************************************** */}

                <Grid item xs={12} mt={1} mb={2}>
                    <Typography variant="h5">Predchádzajúce doklady</Typography>
                    <Typography variant="body2">Vstupné doklady z ktorých tento doklad vychádza</Typography>
                </Grid>

                <Grid item xs={12}>
                    <DocumentCreateRelatedList
                        source={props.source.relatedParents}
                        onPrint={(document) => handlePrint(document.id ?? 0, document.typeId ?? 0)}
                        onDelete={(document) => props.setSource((p) => ({ ...p, relatedParents: p.relatedParents?.filter(item => item !== document) ?? [] }))}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" size="small" startIcon={<ExpandMoreIcon />} color="secondary" sx={{ mt: 1 }} aria-label="Generovať" aria-controls="menu-adddocument" aria-haspopup="true" onClick={(e) => {
                        setAddDocumentParentList(true);
                        setAddDocumentGenerate(true);
                        setAddDocumentEl(e.currentTarget);
                    }}>Generovať</Button>
                    <Button size="small" startIcon={<ExpandMoreIcon />} color="secondary" sx={{ mt: 1, ml: 1 }} aria-label="Vybrať doklad" aria-controls="menu-adddocument" aria-haspopup="true" onClick={(e) => {
                        setAddDocumentParentList(true);
                        setAddDocumentGenerate(false);
                        setAddDocumentEl(e.currentTarget);
                    }}>Vybrať</Button>
                </Grid>

                {/* Nasledujúce doklady  */}
                {/* *************************************************** */}

                <Grid item xs={12} mt={5} mb={2}>
                    <Typography variant="h5">Nasledujúce doklady</Typography>
                    <Typography variant="body2">Výstupné doklady vychádzajúce z tohto dokladu</Typography>
                </Grid>

                <Grid item xs={12}>
                    <DocumentCreateRelatedList
                        source={props.source.relatedChildren}
                        onPrint={(document) => handlePrint(document.id ?? 0, document.typeId ?? 0)}
                        onDelete={(document) => props.setSource((p) => ({ ...p, relatedChildren: p.relatedChildren?.filter(item => item !== document) ?? [] }))}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" size="small" startIcon={<ExpandMoreIcon />} color="secondary" sx={{ mt: 1 }} aria-label="Generovať" aria-controls="menu-adddocument" aria-haspopup="true" onClick={(e) => {
                        setAddDocumentParentList(false);
                        setAddDocumentGenerate(true);
                        setAddDocumentEl(e.currentTarget);
                    }}>Generovať</Button>
                    <Button size="small" startIcon={<ExpandMoreIcon />} color="secondary" sx={{ mt: 1, ml: 1 }} aria-label="Vybrať doklad" aria-controls="menu-adddocument" aria-haspopup="true" onClick={(e) => {
                        setAddDocumentParentList(false);
                        setAddDocumentGenerate(false);
                        setAddDocumentEl(e.currentTarget);
                    }}>Vybrať</Button>
                </Grid>

            </Grid>

            {/* Tlač dokladu */}
            <ReportDocument {...documentPrint} />

            {/* Formulár pre nový záznam */}
            <DocumentCreate {...documentCreate} documentTypes={props.documentTypes} />

            {/* Výber dokladov */}
            <DocumentsDialog {...documentsDialog} types={props.documentTypes} />

        </>
    )
}

export default DocumentCreateRelated;

interface DocumentCreateRelatedListProps {
    source?: DocumentRelated[];
    onPrint: (document: DocumentRelated) => void;
    onDelete: (document: DocumentRelated) => void;
}

const DocumentCreateRelatedList = (props: DocumentCreateRelatedListProps) => {
    return (
        <>
            {(props.source?.length ?? 0) > 0 &&
                <Table size="small">
                    <TableBody>
                        {props.source?.map((document, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>
                                    <strong>{document.number}</strong> - {document.typeName} {document.paid === true && '(uhradené)'}
                                </TableCell>
                                <TableCell style={{ padding: 0, width: '150px', textAlign: 'right' }}>
                                    <IconButton aria-label="Vytlačiť" size="small" onClick={() => props.onPrint(document)}>
                                        <PrintIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton aria-label="Upraviť" size="small" href={AppRouteUrl.DOCUMENTS + (document.typeId ?? 0).toString() + '/?id=' + (document.id ?? 0).toString()} target="_blank">
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton aria-label="Vymazať" size="small" onClick={() => props.onDelete(document)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </>
    )
};