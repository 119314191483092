
export const EscapeRegExp = (value: string): string => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const RemoveAccents = (value: string): string => {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const EscapeSlug = (value: string, lowerCase?: boolean): string => {
    return RemoveAccents((lowerCase === undefined || lowerCase === true ? value.toLowerCase(): value)).replace(/[^a-zA-Z0-9]+/g, '-');
}

export const EscapeUrl = (value: string, lowerCase?: boolean): string => {
    return RemoveAccents((lowerCase === undefined || lowerCase === true ? value.toLowerCase(): value)).replace(/[\s]+/g, '-');
}

export const SearchInText = (search: string, text: string): boolean => {
    return new RegExp(EscapeRegExp(RemoveAccents(search.toLowerCase())), 'i').test(RemoveAccents(text.toString().toLowerCase()));
}