import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { AppRouteUrl } from '../../AppRoutes';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, MathRound2, MathRound4 } from '../../utility/Number';
import { FormatCurrency, FormatDecimal } from '../../utility/Format';
import { NullMinDate } from '../../utility/Date';
import { LoadLocalSettings, SaveLocalSettings } from '../../utility/LocalSettings';

// Modely
import { DocumentType, DocumentItem, DocumentItemTypes, DocumentItemCompute, Product, ProductLite, Unit, Parameter, ParameterItem, ParameterItemPriceCompute, PriceList, ProductVolumeDiscount, Currency, DocumentItemPriceVatModes, DocumentTypeStockUsages, ProductStock, StockMovementsFilter, Warehouse, ProductToLite, DocumentItemFromProduct, ProductGetVolumeDiscount, DocumentItemFillVolumeDiscount, DocumentItemPriceUpdate, DocumentItemPriceUpdateLoad, DocumentItemPriceUpdateSave } from '../../models/Models';

// Komponenty
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Backdrop, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tooltip, Accordion, AccordionDetails, AccordionSummary, Typography, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, FormControlLabel, Chip, Switch, styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ProductsDialog, { ProductsDialogProps } from '../product/ProductsDialog';
import ParameterConfiguratorDialog, { ParameterConfiguratorDialogProps } from '../settings/parameter_configurator/ParameterConfiguratorDialog';
import Iframe from '../../components/Iframe';
import TinyMCEDialog, { TinyMCEDialogProps } from '../tinymce/TinyMCEDialog';
import { TinyMCEStyleClient } from '../tinymce/TinyMCEStyleClient';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import ProductsDetail from '../product/ProductsDetail';
import StockMovementsDialog, { StockMovementsDialogProps } from '../stock_movement/StockMovementsDialog';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import BusinessIcon from '@mui/icons-material/Business';
import WidgetsIcon from '@mui/icons-material/Widgets';
import QrCodeIcon from '@mui/icons-material/QrCode';
import TagIcon from '@mui/icons-material/Tag';
import ScaleIcon from '@mui/icons-material/Scale';
import TopicIcon from '@mui/icons-material/Topic';
import EuroIcon from '@mui/icons-material/Euro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import PercentIcon from '@mui/icons-material/Percent';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& td': {
        padding: theme.spacing(0.5),
    },
    '& th': {
        paddingY: theme.spacing(0.2),
        lineHeight: 1.1,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// Predpis pre argumenty TinyMCE
interface TinyMCEArgument {
    property: string;
}

// Slúži pre automatické načítanie produktu
interface ProductInfo {
    id: number; // id produktu
    product?: Product; // vyplnená trieda produktu
    save?: boolean; // false = predvyplní okno, true = uloží ako novú položku a zavrie okno
}

// Prázdny záznam (predvolený)
const EmptySource = (): DocumentItem => ({
    id: 0,
    parentId: 0,
    productId: 0,
    type: DocumentItemTypes.Product,
    ordinalNumber: 0,
    ean: '',
    mpn: '',
    code: '',
    codeSupplier: '',
    name: '',
    variant: '',
    note: '',
    description: '',
    quantity: 1,
    unit: '',
    priceIncludeVat: false,
    priceVatMode: DocumentItemPriceVatModes.Standard,
    other: {},
    configurator: []
});

// Vstupné parametre
export interface DocumentCreateItemProps {
    documentType: DocumentType;
    warehouses: Warehouse[];
    warehouseId?: number;
    warehouseName?: string;
    item?: DocumentItem;
    itemIndex?: number;
    itemLoadProducts?: ProductLite[]; // Ak podhodím produkt, tak je automaticky načítaný pri zobrazení okna
    units: Unit[],
    parameters: Parameter[];
    vatRates: number[];
    vatRateDefault: number;
    priceIncludeVat: boolean;
    languageDefaultId: number;
    customerDiscount: number;
    customerId: number;
    priceList: PriceList;
    tagDiscount: number;
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    currency: Currency;
    onSave: (item: DocumentItem, itemIndex?: number) => void;
    onClose: () => void;
}

const DocumentCreateItem = (props: DocumentCreateItemProps) => {

    // Stav
    const [loadingList, setLoadingList] = useState<string | undefined>(undefined);
    const [warning, setWarning] = useState<string | undefined>(undefined);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [source, setSource] = useState<DocumentItem>(EmptySource());
    const [product, setProduct] = useState<Product>({});
    const [productVolumeDiscounts, setProductVolumeDiscounts] = useState<ProductVolumeDiscount[]>([]);
    const [configuratorToLoad, setConfiguratorToLoad] = useState<ParameterItem[]>()
    const [productDetail, setProductDetail] = useState<ProductLite | undefined>(undefined);

    // Skladový pohyb (príjem / výdaj)
    const isStockMovement = (props.documentType.stock ?? 0) === DocumentTypeStockUsages.Inbound || (props.documentType.stock ?? 0) === DocumentTypeStockUsages.Outbound;
    const isSale = (props.documentType.sale === true);

    // Aktualizácia cien 
    const priceUpdateActive = (props.documentType.stock ?? 0) === DocumentTypeStockUsages.Inbound && (source.productId ?? 0) > 0; // aktualizácia cien je povolená len pre nové položky príjemky s reláciou na skladovú kartu
    const priceUpdateRememberActive = priceUpdateActive && (source.id ?? 0) === 0; // možnosť zapamätať zvolené možnosti je aktívna len pre nové položky príjemky s reláciou na skladové karty (nepoužíva sa teda pri editácii)
    const priceUpdateRememberLoad = () => LoadLocalSettings('documents-priceupdate-remember', 'false') === 'true'; // načíta uložené nastavenie "zapamäťať" zvolené možnosti
    const [priceUpdateRemember, setPriceUpdateRemember] = useState<boolean>(priceUpdateRememberLoad()); // aktuálny stav nastavenia "zapamäťať" zvolené možnosti
    const priceUpdateInfo = useMemo<string>(() => {
        const info = [
            'predajná',
            'nákupná',
            'dodávateľská'
        ].filter((_, i) => [
            source.priceUpdate?.priceSaleUpdate,
            source.priceUpdate?.pricePurchaseUpdate,
            source.priceUpdate?.priceSupplierUpdate
        ][i] === true);
        return info.length ? info.join(', ') : 'nie';
    }, [source.priceUpdate]);

    // Zoznam pohybov
    const [stockMovementsDialog, setStockMovementsDialog] = useState<StockMovementsDialogProps>({
        open: false,
        keepMounted: false,
        filter: {},
        onClose: () => handleStockMovementsClose()
    });
    const handleStockMovementsOpen = (filter: StockMovementsFilter) => {
        if ((filter.productId ?? 0) <= 0)
            return;
        setStockMovementsDialog(prev => ({ ...prev, open: true, filter: filter }));
    }
    const handleStockMovementsClose = () => {
        setStockMovementsDialog(prev => ({ ...prev, open: false }));
    }

    // Podrobný popis (html editor)
    const [tinyMCEDialog, setTinyMCEDialog] = useState<TinyMCEDialogProps>({
        open: false,
        content: '',
        contentStyle: 'body { font-family: Arial, sans-serif; }', // CSS štýl, ktorý sa pridáva do vygenerovaného HTML ako predvolený štýl
        // contentTagsIds: [],
        onSave: (content: string, argument: TinyMCEArgument) => {
            handleChange(argument.property, content);
            handleTinymceClose();
        },
        onClose: () => handleTinymceClose()
    });
    const handleTinymceOpen = (argument: TinyMCEArgument, title: string, content: string) => {
        setTinyMCEDialog(prev => ({ ...prev, open: true, title: title, argument: argument, content: content }));
    };
    const handleTinymceClose = () => {
        setTinyMCEDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const isAccordionExpanded = (name: string): boolean => accordionExpanded.includes(name);
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Postup pri načítaní produktu
    // -------------------------------------
    // - Všetko je potrebné riešiť cez háčiky, keďže kontext (stav) je rôzny medzi hlavným vláknom a spätným volaním z API
    // - Do zoznamu "productToLoad" sa vloží jeden alebo viacero produktov, ktoré sa majú načítať (pri viacero použiť "save=true", aby sa priebežne ukladali)
    // - Pomocou háčika sa postupne načítavajú všetky produkty z "productToLoad" a odovdzajú do "productLoaded", kde je vyvolaný háčik pre uloženie do zoznamu (viacero produktov) alebo vyplneniu okna (jeden produkt)

    const [productToLoad, setProductToLoad] = useState<ProductInfo[]>();
    const [productLoaded, setProductLoaded] = useState<ProductInfo>();

    // Háčik pre postupné načítanie jedného produktu alebo viacero produktov
    useEffect(() => { productToLoadCallback(); }, [productToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre postupné načítanie jedného produktu alebo viacero produktov
    const productToLoadCallback = async () => {

        // Pokračujem, len ak zoznam produktov ešte nie je vynulovaný
        if (productToLoad === undefined) {
            return;
        }
        if (productToLoad.length === 0) {
            setProductToLoad(undefined);
            setLoadingList(undefined);
            return;
        }

        // Produkt na spracovanie (držím v pamäti)
        const productInfo = productToLoad[0];

        // Zavriem okno ak spracovávam dávku
        if (props.open && productInfo.save === true) {
            props.onClose();
        }

        // Zobrazím preloader 
        setLoadingList('Spracovanie... zostáva: ' + productToLoad.length);

        // Pozastavím vlákno, aby sa stihol aktualizovať stav nadradeného komponentu
        await sleep(250);

        // Získam kompletnú triedu produktu
        axios
            .get(AppConfig.ApiUri + 'product/' + productInfo.id)
            .then(response => {
                if (response.data !== null) {
                    // Vyplním triedu produktu a vyvolám háčik pre spracovanie produktu
                    productInfo.product = response.data as Product;
                    setProductLoaded({ ...productInfo });
                }
                // Odstránim spracovanú položku a uložím stav aby sa spracoval ďalší v poradí
                setProductToLoad(prev => {
                    var changed = [...(prev ?? [])];
                    if (changed.length > 0) {
                        changed.shift();
                    }
                    return changed;
                });
            });
    };

    // Háčik s funkciou pre automatické vloženie načítaného produktu do zoznamu položiek (viacero) alebo vyplnenie okna (jeden)
    useEffect(() => {
        // Pokračujem, len ak ešte nie je vynulovaný
        if (productLoaded === undefined) {
            return;
        }

        // Uchovám vybraný produkt a vynulujem, aby sa háčik nevyvolal viac krát
        const productInfo = productLoaded;
        setProductLoaded(undefined);

        // Trieda produktu musí byť vyplnená
        if (productInfo.product === undefined) {
            return;
        }

        // Pretypujem triedu produktu na položku dokladu
        let documentItem = DocumentItemFromProduct(productInfo.product, {
            sale: isSale,
            languageDefaultId: props.languageDefaultId,
            warehouseId: props.warehouseId,
            tagDiscount: props.tagDiscount,
            customerDiscount: props.customerDiscount,
            customerId: props.customerId,
            priceList: props.priceList,
            priceIncludeVat: props.priceIncludeVat,
            parameters: props.parameters,
            units: props.units
        });

        // Pri novej položke príjemky s reláciou na produkt načítam uložené nastavenia pre aktualizáciu cien
        if ((props.documentType.stock ?? 0) === DocumentTypeStockUsages.Inbound && (source.id ?? 0) === 0) {
            documentItem.priceUpdate = DocumentItemPriceUpdateLoad() ?? documentItem.priceUpdate;
            if (documentItem.priceUpdate !== undefined) {
                setAccordionExpanded(['priceupdate']);
            }
        }

        // Získam množstevné zľavy pre aktuálny produkt (stav "productVolumeDiscounts" je v tejto časti neaktuálny, je obnovený až po použití "setProduct")
        let volumeDiscounts = ProductGetVolumeDiscount(props.priceList, productInfo.product);

        // ------------------------------------------------------------
        // Hromadné spracovanie 
        // ------------------------------------------------------------

        // Automatické uloženie každej položky 
        if (productInfo.save === true) {
            documentItem = { ...EmptySource(), ...documentItem }; // Zlúčim s "EmptySource", keďže pri hromadnom spracovaní sa nepoužívajú vyplnené údaje z okna
            documentItem = DocumentItemFillVolumeDiscount(documentItem, isSale, volumeDiscounts); // Aplikujem množstevnú zľavu
            documentItem = DocumentItemCompute(documentItem); // Prepočítam položku
            props.onSave(documentItem); // Vložím do zoznamu (pri hromadnom spracovaní sa priebežne ukladajú)
            return; // Ďalej nepokračujem
        }

        // ------------------------------------------------------------
        // Štandardné spracovanie 
        // ------------------------------------------------------------

        // Uchovám vybraný produkt
        setProduct(productInfo.product);

        // Vyvolám zmenu stavu položky (zlúčim pôvodnú položku a novú)
        setSource(prev => {
            let next = { ...prev, ...documentItem }; // Zlúčim s aktuálne vyplnenou položkou (predchádzajúce stav) v okne, aby sa zachovali ostatné parametre
            next = DocumentItemFillVolumeDiscount(next, isSale, volumeDiscounts); // Aplikujem množstevnú zľavu
            next = DocumentItemCompute(next); // Prepočítam položku
            return next;
        });

        // Automaticky označím množstvo pre zmenu
        quantityFocus();

        // Vyvolám okno pre konfigurátor (zobrazí sa len ak je možné vybrať konfiguráciu)
        handleParameterConfiguratorOpen(productInfo.product.parameters);

    }, [productLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre pozastavenie vlákna
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    // Referencie na elementy
    const quantityRef = useRef<HTMLInputElement>();

    // Funkcia pre načítanie a vyplnenie okna
    const loadData = useCallback(() => {
        // Po zatvorení okna upravím zobrazenie a ukončím sledovanie zmien
        if (!props.open) {
            setPriceUpdateRemember(priceUpdateRememberLoad());
            setAccordionExpanded([]);
            return;
        }

        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        handleReset();

        setPriceUpdateRemember(LoadLocalSettings('documents-priceupdate-remember', 'true') === 'true');

        // Použijem props.item alebo emptySource (automaticky spustím prepočet, aby sa doplnili dopočítavané hodnoty)
        setSource(DocumentItemCompute(props.item ?? {
            ...EmptySource(),
            unit: (props.units.find(unit => unit.other?.translationName === 'ks') !== undefined ? 'ks' : ''),
            priceIncludeVat: props.priceIncludeVat,
            priceVatRate: props.vatRateDefault
        }));

        // Ak je na vstupe podhodený jeden produkt alebo zoznamu produktov, tak ho automaticky načítam
        if (props.itemLoadProducts !== undefined && (props.itemLoadProducts?.length ?? 0) > 0) {
            setProductToLoad(props.itemLoadProducts.map(product => ({ id: product.id, save: ((props.itemLoadProducts?.length ?? 0) > 1) } as ProductInfo)));
        }

        // Ak existuje relačný produkt, tak ho dotiahnem
        if (props.item && (props.item.productId ?? 0) > 0) {
            axios
                .get(AppConfig.ApiUri + 'product/' + props.item.productId)
                .then(response => {
                    if (response.data !== null) {
                        setProduct(response.data as Product);
                    }
                });
        } else {
            setProduct({});
        }
    }, [props.item, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning !== undefined) {
            setWarning(undefined);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = (keepId?: boolean) => {
        setProduct({});
        setSource(prev => ({
            ...EmptySource(),
            id: (keepId ?? false) === true ? prev.id : 0,
            ordinalNumber: prev.ordinalNumber,
            unit: (props.units.find(unit => unit.other?.translationName === 'ks') !== undefined ? 'ks' : ''),
            priceIncludeVat: props.priceIncludeVat,
            priceVatRate: props.vatRateDefault
        }))
    };
    const handleResetConfirm = (keepId?: boolean) => {
        setConfirm(prev => ({
            ...prev, open: true, title: 'Reset', children: 'Skutočne chcete zrušiť všetky vykonané zmeny?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                loadData();
            }
        }));
    }

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        // Pri novej položke príjemky s reláciou na produkt uložím po potvrdení položky zvolené možnosti pre aktualizáciu cien
        if (priceUpdateRememberActive === true) {
            DocumentItemPriceUpdateSave(priceUpdateRemember, source.priceUpdate);
        }

        // Uložím položku a zavriem okno
        props.onSave(source, props.itemIndex);
        props.onClose();
    };

    // Zrušenie relácie s produktom
    const handleProductRelationCancel = () => {
        // Nie je možná zmena relácie na existujúcej položke pohybu
        if (isStockMovement === true && (source.id ?? 0) > 0) {
            return;
        }
        setProduct({});
        setSource(prev => ({
            ...prev,
            productId: 0,
            other: {}
        }))
    };

    // Automaticky vyplním vybrané položky konfigurátora po označení, musím to vykonať cez stav a háčik, keďže v callbacku zo zoznamu nemám vyplnenú inštanciu 'props'
    useEffect(() => {
        // Pokračujem, len ak nie je vynulovaný
        if (configuratorToLoad === undefined) {
            return;
        }

        // Uchovám vybraný produkt a vynulujem, aby sa háčik nevyvolal viac krát
        const configuratorItems = configuratorToLoad;
        setConfiguratorToLoad(undefined);

        // Aktualizujem konfigurátor
        setSource(prev => DocumentItemCompute({
            ...prev,
            configurator: [
                ...(prev.configurator ?? [])?.filter(item => item.uid !== 'configurator') ?? [],
                ...configuratorItems.map(item => ({
                    uid: 'configurator',
                    name: props.parameters?.find(parameter => parameter.id === item.parameterId)?.other?.translationName ?? '',
                    value: item.other?.translationName,
                    price: ParameterItemPriceCompute(item, props.priceList, props.customerDiscount)
                }))
            ]
        }));
    }, [configuratorToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    // Výber z konfigurátoru
    const [parameterConfiguratorDialog, setParameterConfiguratorDialog] = useState<ParameterConfiguratorDialogProps>({
        open: false,
        keepMounted: false,
        onSelect: (items: ParameterItem[]) => {
            if (items.length > 0) {
                // Vyvolám háčik pre načítanie položiek konfigurátora (v callback nemám prístup k 'props')
                setConfiguratorToLoad(items);

                // Automaticky rozbalím panel konfigurátora
                setAccordionExpanded(['configurator']);

                // Automaticky označím množstvo pre zmenu
                quantityFocus();
            }
            handleParameterConfiguratorClose();
        },
        onClose: () => {
            handleParameterConfiguratorClose();
            quantityFocus();
        }
    });

    // Funkcia pre "focus" množstva
    const quantityFocus = () => {
        if (quantityRef && quantityRef.current) {
            var target = quantityRef.current;
            setTimeout(() => {
                target.focus();
                target.select();
            }, 100);
        }
    };

    // Otvorenie konfigurátora (ak neexistuje žiadny parameter typu konfigurátor, ktorý by obsahoval vybrané položky parametrov (alebo sú prázdne), tak neotváram)
    const handleParameterConfiguratorOpen = (parametersItems?: number[]) => {
        if (parametersItems === undefined || props.parameters.find(parameter => (parameter.type === 2 || parameter.type === 3) && parameter?.items?.find(item => parametersItems?.includes(item.id ?? 0))) === undefined) {
            return;
        }
        setParameterConfiguratorDialog(prev => ({ ...prev, open: true, parametersItems: parametersItems }));
    };
    const handleParameterConfiguratorClose = () => {
        setParameterConfiguratorDialog(prev => ({ ...prev, open: false }));
    };

    // Výber existujúceho produktu
    const [productsDialog, setProductsDialog] = useState<ProductsDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (products: ProductLite[]) => {
            if (products.length > 0) {
                setProductToLoad(products.map(product => ({ id: product.id, save: (products.length > 1) } as ProductInfo)));
            }
            handleProductsClose();
        },
        onClose: () => handleProductsClose()
    });

    // Zobrazenie produktov (pri prvom ponecham komponent pripnutý aby som nemusel znovu naťahovať všetky číselníky)
    const handleProductsOpen = () => {
        // Nie je možná zmena relácie na existujúcej položke pohybu
        if (isStockMovement === true && (source.id ?? 0) > 0) {
            return;
        }
        setProductsDialog(prev => ({ ...prev, open: true /*, keepMounted: true - citelne to spomaluje form */ }));
    };
    const handleProductsClose = () => {
        setProductsDialog(prev => ({ ...prev, open: false }));
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
            // Automaticky označím text vo vybraných poliach
            if ((props.autoFocus === 'quantity' ||
                props.autoFocus === 'weight' ||
                props.autoFocus === 'price' ||
                props.autoFocus === 'priceCalc') &&
                (input?.select !== undefined && typeof input.select === 'function')) {
                setTimeout(() => { input?.select(); }, 250);
            }
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Pridať, upraviť alebo vymazať parameter konfigurátor
    const handleChangeConfigurator = (index: number, property: string, value: any) => {
        setSource(prev => {
            let changed = prev.configurator ?? [];
            changed[index] = { ...changed[index], [property]: value };
            let next: DocumentItem = { ...prev, configurator: changed };
            // Prepočet položky spustím len ak som zmenil niektorú z hodnôt, ktoré ho ovplyvňujú
            if (property === 'price') {
                next = DocumentItemFillVolumeDiscount(next, isSale, productVolumeDiscounts); // Aplikujem množstevnú zľavu (priamo ju ovplyvňuje cena aj množstvo)
                next = DocumentItemCompute(next); // Prepočítam položku
            }
            return next;
        });
    }
    const handleCreateConfigurator = () => {
        setSource(prev => ({ ...prev, configurator: [...(prev.configurator ?? []), { uid: '', name: '', value: '' }] })); // Nemusím vyvolať žiadny prepočet, keďže nulová cena nič neovplyvní)
    }
    const handleDeleteConfigurator = (index: number) => {
        setSource(prev => {
            let changed = prev.configurator ?? [];
            let next: DocumentItem = { ...prev, configurator: changed.filter(deleted => deleted !== changed[index]) ?? [] };
            next = DocumentItemFillVolumeDiscount(next, isSale, productVolumeDiscounts); // Aplikujem množstevnú zľavu (priamo ju ovplyvňuje cena aj množstvo)
            next = DocumentItemCompute(next); // Prepočítam položku
            return next;
        });
    }

    // Aby nebolo potrebné pri každom "OnChange" získavať množstevné zľavy, tak ich budem udržiavať v stave a obnovím po každej zmene cenníka alebo produktu
    useEffect(() => setProductVolumeDiscounts(ProductGetVolumeDiscount(props.priceList, product)), [props.priceList, product]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }

        // Prepočet položky spustím len ak som zmenil niektorú z hodnôt, ktoré ho ovplyvňujú
        let compute = [
            'quantity',
            'price',
            'priceCalc',
            'priceDiscount',
            'priceIncludeVat',
            'priceVatRate',
            'priceVatMode'
        ].includes(property);

        // Upraví stav položky
        setSource(prev => {
            let next: DocumentItem = ({ ...prev, [property]: value });
            if (compute) {
                next = DocumentItemFillVolumeDiscount(next, isSale, productVolumeDiscounts); // Aplikujem množstevnú zľavu (priamo ju ovplyvňuje cena aj množstvo)
                next = DocumentItemCompute(next, property); // Prepočítam položku
            }
            return next;
        });
    }
    const handleChangePriceUpdate = (property: string, value: any) => {
        setSource(prev => ({ ...prev, priceUpdate: { ...prev.priceUpdate, [property]: value } }));
    }

    // Informácie o vybranom produkte
    const productInfo = (product.code ?? '') + ' - ' + (product?.translations?.find(translation => translation.languageId === props.languageDefaultId)?.name ?? '');

    // Informačná cena
    const priceStockTotal = MathRound2((source.priceStock ?? 0) * (source.quantity ?? 0));
    const priceProfit = (props.documentType.stock ?? 0) === DocumentTypeStockUsages.Outbound ? (source.priceTotalNoVat ?? 0) - priceStockTotal : 0; // zisk sa počíta len pre výdaj

    // Aktuálne zásoby
    const productStock = useMemo<ProductStock | undefined>(() => {
        // Vybraný sklad sklad
        if ((props.warehouseId ?? 0) > 0) {
            return product.stocks?.find(stock => stock.warehouseId === props.warehouseId);
        }
        // Celkový stav skladu
        return {
            quantity: product.stockQuantity,
            reservedQuantity: product.stockReservedQuantity,
            orderedQuantity: product.stockOrderedQuantity,
            minimalQuantity: product.stockMinimalQuantity,
            maximalQuantity: product.stockMaximalQuantity
        }
    }, [product, props.warehouseId]);

    return (
        <>
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loadingList !== undefined}>
                <CircularProgress color="inherit" />
                <Typography ml={2}>{loadingList}</Typography>
            </Backdrop>

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {props.itemIndex === undefined ? 'Nová položka' : 'Položka'}
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={8}>
                                <TextField margin="dense" label="Existujúci produkt" fullWidth variant="outlined" autoComplete="off" disabled
                                    value={((product.id ?? 0) > 0 ? productInfo : '...')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <WidgetsIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={4} sx={{ position: 'relative' }}>
                                {(product.id ?? 0) > 0 && (
                                    <Chip label={'rez: ' + (productStock?.reservedQuantity ?? 0) + ', obj: ' + (productStock?.orderedQuantity ?? 0)} color="secondary" size="small" sx={{ position: 'absolute', top: '0px', right: '-3px', height: 'auto', fontSize: '12px', zIndex: 3 }} />
                                )}
                                <TextField fullWidth margin="dense" type="text" label={'Zásoby' + ((props.warehouseId ?? 0) > 0 ? ' (' + (props.warehouseName ?? 'sklad') + ')' : '')} variant="outlined" autoComplete="off" disabled
                                    value={productStock?.quantity ?? '0'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <BusinessIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(source.productId ?? 0) > 0 && (
                                <Grid item xs={12}>
                                    <Alert severity='info'>Zmena údajov v tomto formulári nemá vplyv na existujúci produkt.</Alert>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Button variant="contained" size="small" startIcon={<TopicIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} onClick={handleProductsOpen} disabled={isStockMovement === true && (source.id ?? 0) > 0}>Vybrať</Button>
                                {(source.productId ?? 0) > 0 && <>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => { setProductToLoad([{ id: source.productId ?? 0 }]); }}>Obnoviť</Button>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} href={AppRouteUrl.PRODUCTS + '?id=' + (source.productId ?? 0).toString()} target="_blank">Detail</Button>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => setProductDetail(ProductToLite(product))}>Zásoby</Button>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={handleProductRelationCancel} disabled={isStockMovement === true && (source.id ?? 0) > 0}>Zrušiť prepojenie</Button>
                                </>}
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    sx={{ background: 'rgba(247,255,0,.05)' }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sm={3} sx={{ position: 'relative' }}>
                                {(() => {
                                    if ((product.id ?? 0) === 0 || (props.documentType.stock ?? 0) !== DocumentTypeStockUsages.Outbound)
                                        return null;
                                    var restQuantity = MathRound4((productStock?.quantity ?? 0) - (source.quantity ?? 0));
                                    var restColor: 'success' | 'secondary' | 'error' = restQuantity > 0 ? 'success' : restQuantity < 0 ? 'error' : 'secondary';
                                    return <Chip label={'zostáva: ' + restQuantity} color={restColor} size="small" sx={{ position: 'absolute', top: '0px', right: '-3px', height: 'auto', fontSize: '12px', zIndex: 3 }} />
                                })()}

                                <TextField required fullWidth margin="dense" name="quantity" type="text" label="Množstvo" variant="outlined" autoComplete="off" value={source.quantity ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    sx={{ background: 'rgba(247,255,0,.05)' }}
                                    inputRef={input => props.autoFocus === 'quantity' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown, ref: quantityRef }} />
                                {(source.unit ?? '').length === 0 && (
                                    <Typography textAlign='center' sx={{ position: 'absolute', top: '23px', right: '30px', zIndex: 1, opacity: .3, fontSize: '12px', lineHeight: 1.1 }}>merná<br />jednotka</Typography>
                                )}

                                <Select value={(source.unit ?? '')} name="unit" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    sx={{ position: 'absolute', top: '22px', right: '7px', zIndex: 2, minWidth: '70px', textAlign: 'center' }} variant='standard' size='small' autoWidth disableUnderline
                                    inputRef={input => props.autoFocus === 'unit' && setFocus(input)}>
                                    <MenuItem value={''}>-</MenuItem>
                                    {props.units.map(unit => (<MenuItem key={unit.id} value={unit.other?.translationName}>{unit.other?.translationName ?? ''}</MenuItem>))}
                                </Select>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth margin="dense" name="quantityReserved" type="text" label="Množstvo rezervované" variant="outlined" autoComplete="off" value={source.quantityReserved ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    disabled={props.documentType.stock !== DocumentTypeStockUsages.Reserved}
                                    inputRef={input => props.autoFocus === 'quantityReserved' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <HistoryToggleOffIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth margin="dense" name="quantityDelivered" type="text" label="Množstvo dodané" variant="outlined" autoComplete="off" value={source.quantityDelivered ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    disabled={props.documentType.stock !== DocumentTypeStockUsages.Reserved && props.documentType.stock !== DocumentTypeStockUsages.Ordered}
                                    inputRef={input => props.autoFocus === 'quantityDelivered' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <HistoryToggleOffIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth margin="dense" name="weight" type="text" label="Hmotnosť MJ v gramoch" variant="outlined" autoComplete="off" value={source.weight ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'weight' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ScaleIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth required type="text" margin="dense" name="price" label={'Cena ' + (source.priceIncludeVat ? '(s DPH)' : '(bez DPH)')} variant="outlined" autoComplete="off" value={source.price ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    sx={{ background: 'rgba(247,255,0,.05)' }}
                                    inputRef={input => props.autoFocus === 'price' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth margin="dense" name="priceCalc" type="text" label={'Cena ' + (!source.priceIncludeVat ? '(s DPH)' : '(bez DPH)')} variant="outlined" autoComplete="off" value={source.priceCalc ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'priceCalc' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="product-create-vatrate">DPH</InputLabel>
                                    <Select required labelId="product-create-vatrate" label="DPH" value={source.priceVatRate ?? 0} name="priceVatRate" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'priceVatRate' && setFocus(input)}>
                                        {(props.vatRates ?? []).length === 0 && <MenuItem value={0}>0 %</MenuItem>}
                                        {props.vatRates.map(item => <MenuItem key={item} value={item}>{item} %</MenuItem>)}
                                    </Select>

                                    {/* PDP zobrazujem iba pri predajnom doklade, alebo pri položke, ktorá ju má aktívnu (môže vychádzať z kópie predajného dokladu, aby sa dala vypnúť) */}
                                    {(props.documentType?.sale === true || source.priceVatMode === DocumentItemPriceVatModes.ReverseCharge) && <>
                                        <FormControlLabel sx={{ position: 'absolute', top: '8px', right: '15px' }} title='Prenos daňovej povinnosti (reverse charge)'
                                            label={<Typography variant='body2'>PDP</Typography>}
                                            control={<Checkbox size='small' color='secondary' sx={{ pr: 0.5 }}
                                                checked={source.priceVatMode === DocumentItemPriceVatModes.ReverseCharge}
                                                onChange={(e) => handleChange('priceVatMode', e.target.checked === true ? DocumentItemPriceVatModes.ReverseCharge : DocumentItemPriceVatModes.Standard)}
                                            />} />
                                    </>}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth type="text" margin="dense" name="priceDiscount" label='Zľava %' variant="outlined" autoComplete="off" value={source.priceDiscount ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'priceDiscount' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PercentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item container xs={12} columnSpacing={1}>

                                <Grid item xs={12} sm={3} order={(source.priceIncludeVat ?? true) ? 1 : 2}>
                                    <TextField fullWidth disabled margin="dense" type="number" label="Jednotková cena (s DPH)" variant="outlined" autoComplete="off" value={MathRound2((source.priceUnitNoVat ?? 0) + (source.priceUnitVatAmount ?? 0))}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EuroIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={3} order={(source.priceIncludeVat ?? true) ? 2 : 1}>
                                    <TextField fullWidth disabled margin="dense" type="number" label="Jednotková cena (bez DPH)" variant="outlined" autoComplete="off" value={source.priceUnitNoVat ?? ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EuroIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={3} order={3}>
                                    <TextField fullWidth disabled margin="dense" type="number" label="Jednotková cena DPH" variant="outlined" autoComplete="off" value={source.priceUnitVatAmount ?? ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EuroIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={3} order={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                        <FormControl margin="dense" fullWidth>
                                            <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Záruka do" value={NullMinDate(source.expirationDate)} onChange={(d) => { handleChange('expirationDate', d, 'date') }} inputRef={input => { props.autoFocus === 'expirationDate' && setFocus(input) }} />
                                        </FormControl>
                                    </LocalizationProvider>
                                </Grid>

                            </Grid>

                            {props.priceIncludeVat === true && source.priceVatMode === DocumentItemPriceVatModes.ReverseCharge && (
                                <Grid item xs={12} mt={1}>
                                    <Alert severity='warning'>
                                        Pri výpočte DPH zhora (B2C - ceny sú vrátane DPH) je potrebné aj pri PDP ponechať nastavenú výšku DPH, aby systém dokázal správne vypočítať jednotkovú cenu bez DPH.
                                        Jednotková cena bude v režime prenosu daňovej povinnosti pracovať vždy s nulovou sadzbou DPH.
                                    </Alert>
                                </Grid>
                            )}

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={6} sm={2}>
                                <Typography variant='body2'>
                                    Celkom bez DPH:<br />
                                    Celkom DPH:<br />
                                    Celkom s DPH:
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sm={2} textAlign='right'>
                                <Typography variant='body2'>
                                    {FormatCurrency(source.priceTotalNoVat ?? 0, props.currency, 2)}<br />
                                    {FormatCurrency(source.priceTotalVatAmount ?? 0, props.currency, 2)}<br />
                                    {FormatCurrency(MathRound2((source.priceTotalNoVat ?? 0) + (source.priceTotalVatAmount ?? 0)), props.currency, 2)}
                                </Typography>
                            </Grid>

                            {isStockMovement === true && (
                                <>
                                    <Grid item xs sm></Grid>

                                    <Grid item xs={6} sm={2}>
                                        <Typography variant='body2'>
                                            Skladová cena (VNC):<br />
                                            Skladová cena celkom:<br />
                                            {(props.documentType.stock ?? 0) === DocumentTypeStockUsages.Outbound && <>Celkový zisk (bez DPH):</>}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} sm={2} textAlign='right'>
                                        <Typography variant='body2'>
                                            {FormatCurrency(source.priceStock ?? 0, props.currency, 4)}<br />
                                            {FormatCurrency(MathRound2(priceStockTotal), props.currency, 2)}<br />
                                            {(props.documentType.stock ?? 0) === DocumentTypeStockUsages.Outbound && <>{FormatCurrency(MathRound2(priceProfit), props.currency, 2)}</>}
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>

                                {priceUpdateActive && (
                                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('priceupdate')} onChange={() => handleToggleAccordion('priceupdate')}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>
                                                Aktualizácia cien ({priceUpdateInfo})
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Table size="small" sx={{ minWidth: '500px' }}>
                                                <TableBody>

                                                    <StyledTableRow>
                                                        <TableCell sx={{ width: '40%' }}>
                                                            <FormControlLabel
                                                                control={<Switch checked={source.priceUpdate?.priceSaleUpdate ?? false} name="priceSaleUpdate" onChange={(e) => { handleChangePriceUpdate(e.target.name, e.target.checked) }} />}
                                                                label={<Typography>Aktualizovať <strong>predajnú cenu</strong></Typography>} />
                                                        </TableCell>
                                                        <TableCell sx={{ textAlign: 'right' }}>
                                                            nová predajná cena:
                                                        </TableCell>
                                                        <TableCell sx={{ width: '20%' }}>
                                                            <TextField fullWidth size="small" name="priceSale" variant="outlined" autoComplete="off" placeholder='Automaticky'
                                                                value={source.priceUpdate?.priceSale}
                                                                onChange={(e) => handleChangePriceUpdate(e.target.name, ConvertToDecimal(e.target.value))}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <EuroIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {(source.priceIncludeVat ? 's DPH' : 'bez DPH')}
                                                        </TableCell>
                                                    </StyledTableRow>

                                                    <StyledTableRow>
                                                        <TableCell sx={{ width: '40%' }}>
                                                            <FormControlLabel
                                                                control={<Switch checked={source.priceUpdate?.pricePurchaseUpdate ?? false} name="pricePurchaseUpdate" onChange={(e) => { handleChangePriceUpdate(e.target.name, e.target.checked) }} />}
                                                                label={<Typography>Aktualizovať <strong>nákupnú cenu</strong></Typography>} />
                                                        </TableCell>
                                                        <TableCell sx={{ textAlign: 'right' }}>
                                                            nová nákupná cena:
                                                        </TableCell>
                                                        <TableCell sx={{ width: '20%' }}>
                                                            <TextField fullWidth size="small" name="pricePurchase" variant="outlined" autoComplete="off" placeholder={FormatDecimal(source.priceUnitNoVat ?? 0, 4)}
                                                                value={source.priceUpdate?.pricePurchase}
                                                                onChange={(e) => handleChangePriceUpdate(e.target.name, ConvertToDecimal(e.target.value))}
                                                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <EuroIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            bez DPH
                                                        </TableCell>
                                                    </StyledTableRow>

                                                    <StyledTableRow>
                                                        <TableCell sx={{ width: '40%' }}>
                                                            <FormControlLabel
                                                                control={<Switch checked={source.priceUpdate?.priceSupplierUpdate ?? false} name="priceSupplierUpdate" onChange={(e) => { handleChangePriceUpdate(e.target.name, e.target.checked) }} />}
                                                                label={<Typography>Aktualizovať <strong>dodávateľskú cenu</strong></Typography>} />
                                                        </TableCell>
                                                        <TableCell sx={{ textAlign: 'right' }}>
                                                            nová dodávateľská cena:
                                                        </TableCell>
                                                        <TableCell sx={{ width: '20%' }}>
                                                            <TextField fullWidth size="small" name="priceSupplier" variant="outlined" autoComplete="off" placeholder={FormatDecimal(source.priceUnitNoVat ?? 0, 4)}
                                                                value={source.priceUpdate?.priceSupplier}
                                                                onChange={(e) => handleChangePriceUpdate(e.target.name, ConvertToDecimal(e.target.value))}
                                                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <EuroIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            bez DPH
                                                        </TableCell>
                                                    </StyledTableRow>

                                                </TableBody>
                                            </Table>

                                            {priceUpdateRememberActive === true && (
                                                <FormControlLabel
                                                    sx={{ mt: 2, ml: .3 }}
                                                    control={<Switch size='small' checked={priceUpdateRemember} name="actived" onChange={(e) => { setPriceUpdateRemember(prev => !prev); }} />}
                                                    label={<Typography variant='body2'>Zapamätať a použiť posledné zvolené možnosti na nové položky</Typography>} />
                                            )}

                                        </AccordionDetails>
                                    </Accordion>
                                )}

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('variant')} onChange={() => handleToggleAccordion('variant')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Variant ({(source.variant?.length ?? 0) > 0 ? source.variant : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField margin="dense" name="variant" label="Variant" fullWidth variant="outlined" autoComplete="off" value={source.variant ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'variant' && setFocus(input)}
                                                    inputProps={{ maxLength: 255 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('configurator')} onChange={() => handleToggleAccordion('configurator')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Konfigurácia ({(source.configurator?.length ?? 0) > 0 ? (source.configurator?.length ?? 0) : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {(source?.configurator?.length ?? 0) > 0 &&
                                            <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                                                <div style={{ flexGrow: 1 }}>
                                                    <Table size="small" sx={{ minWidth: '500px' }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Názov</TableCell>
                                                                <TableCell>Hodnota</TableCell>
                                                                <TableCell>{'Cena ' + (props.priceIncludeVat ? '(s DPH)' : '(bez DPH)')}</TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {source?.configurator?.map((configuration, index) => (
                                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                        <TextField fullWidth size="small" name="name" variant="outlined" autoComplete="off" value={configuration.name ?? ''}
                                                                            disabled={configuration.uid === 'volumediscount'}
                                                                            onChange={(e) => { handleChangeConfigurator(index, e.target.name, e.target.value) }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <EditIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }} />
                                                                    </TableCell>
                                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                        <TextField fullWidth size="small" name="value" variant="outlined" autoComplete="off" value={configuration.value ?? ''}
                                                                            disabled={configuration.uid === 'volumediscount'}
                                                                            onChange={(e) => { handleChangeConfigurator(index, e.target.name, e.target.value) }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <EditIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }} />
                                                                    </TableCell>
                                                                    <TableCell sx={{ width: '20%', p: 0.5, pl: 0 }}>
                                                                        <TextField fullWidth size="small" name="price" type="text" variant="outlined" autoComplete="off" value={configuration.price ?? ''}
                                                                            disabled={configuration.uid === 'volumediscount'}
                                                                            onChange={(e) => { handleChangeConfigurator(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <Tooltip title="Príplatok, ktorý bude pripočítaný k jednotkovej cene.">
                                                                                            <EuroIcon className="formIconHelp" />
                                                                                        </Tooltip>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }} />
                                                                    </TableCell>
                                                                    <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                                        <IconButton aria-label="Vymazať"
                                                                            disabled={configuration.uid === 'volumediscount'}
                                                                            onClick={() => handleDeleteConfigurator(index)}>
                                                                            <DeleteIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        }
                                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleCreateConfigurator()}>Pridať parameter</Button>

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('code')} onChange={() => handleToggleAccordion('code')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Kódy</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={3}>
                                                <TextField margin="dense" name="code" label="Kód" fullWidth variant="outlined" autoComplete="off" value={source.code ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'code' && setFocus(input)}
                                                    inputProps={{ maxLength: 64 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TagIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <TextField margin="dense" name="ean" label="EAN" fullWidth variant="outlined" autoComplete="off" value={source.ean ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'ean' && setFocus(input)}
                                                    inputProps={{ maxLength: 25 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <QrCodeIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <TextField margin="dense" name="codeSupplier" label="Kód dodávateľa" fullWidth variant="outlined" autoComplete="off" value={source.codeSupplier ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'codeSupplier' && setFocus(input)}
                                                    inputProps={{ maxLength: 64 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TagIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <TextField margin="dense" name="mpn" label="Kód výrobcu - MPN" fullWidth variant="outlined" autoComplete="off" value={source.mpn ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'mpn' && setFocus(input)}
                                                    inputProps={{ maxLength: 25 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TagIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('description')} onChange={() => handleToggleAccordion('description')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Podrobný popis ({(source.description?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} sx={{ height: '300px' }}>
                                                <Iframe title={'Podrobný popis'} content={(source.description) + '<style>' + (tinyMCEDialog.contentStyle ?? '') + TinyMCEStyleClient + '</style>'} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => { handleTinymceOpen({ property: 'description' }, 'Podrobný popis', source.description ?? ''); }}>Upraviť</Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={3} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                                    inputProps={{ maxLength: 255 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            {(warning !== undefined && <Alert sx={{ mt: 1 }} severity='error'>{warning}</Alert>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleResetConfirm()} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>

                {/* Výber produktu (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <ProductsDialog {...productsDialog} filter={{ stockWarehouseId: props.warehouseId }} />

                {/* Konfigurátor (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <ParameterConfiguratorDialog {...parameterConfiguratorDialog} parameters={props.parameters} />

                {/* Zoznam pohybov  */}
                <StockMovementsDialog {...stockMovementsDialog} />

                {/* Zásoby */}
                <ProductsDetail
                    product={productDetail}
                    warehouses={props.warehouses}
                    warehouseIdHighlight={props.warehouseId}
                    onOpenStockMovements={(filter?: StockMovementsFilter) => {
                        handleStockMovementsOpen({ ...filter, productId: productDetail?.id });
                        setProductDetail(undefined);
                    }}
                    onClose={() => setProductDetail(undefined)}
                />
            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            <TinyMCEDialog {...tinyMCEDialog} />
        </>
    )
}

export default DocumentCreateItem;
