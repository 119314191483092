import { FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import { AppContext } from '../../AppContext';
import axios from 'axios';

// Utility
import { format as dateFormat } from 'date-fns';
import { FormatCurrency } from '../../utility/Format';
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, MathRound2, MathRound4 } from '../../utility/Number';
import { NullMinDate } from '../../utility/Date';
import addDays from 'date-fns/addDays';

// Modely
import { Country, Currency, Domain, Document, DocumentItem, DocumentItemsDiscountCompute, DocumentItemTypes, DocumentState, Parameter, Payment, PriceList, ResponseData, Shipment, Unit, VolumeDiscount, CustomerBase, Customer, CustomerBaseFromCustomer, DocumentType, DocumentCopy, ExternalDocumentType, DocumentItemCompute, Modules, NumberSequence, Department, Warehouse, StockMovementType, DocumentTypeStockUsages, DocumentItemPriceVatModes } from '../../models/Models';

// Komponenty
import { Alert, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, Box, Snackbar, IconButton, Accordion, AccordionDetails, AccordionSummary, Tab, Tabs, Typography, FormControl, InputLabel, MenuItem, Select, InputAdornment, TextField, FormControlLabel, Switch, Table, TableBody, TableCell, TableRow, ButtonGroup, Divider, ListItemIcon, Menu, Chip, ListSubheader } from '@mui/material';
import Grid from '@mui/material/Grid';
import { TabContext, TabPanel } from '@mui/lab';
import CustomerBaseComponent, { CustomerBaseComponentAction } from '../customer_base/CustomerBaseComponent';
import DocumentCreateItems from './DocumentCreateItems';
import DocumentCreateHistory from './DocumentCreateHistory';
import DocumentCreateNotes from './DocumentCreateNotes';
import DocumentCreatePayments from './DocumentCreatePayments';
import DocumentCreateSummary from './DocumentCreateSummary';
import DocumentCreateRelated from './DocumentCreateRelated';
import DocumentCreateRelatedExternal from './DocumentCreateRelatedExternal';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import CustomFieldValues from '../settings/custom_field/CustomFieldValues';
import DocumentCreateFiles from './DocumentCreateFiles';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DocumentCreateMovement, { DocumentCreateMovementProps } from './DocumentsMovement';

// Ikony
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NumbersIcon from '@mui/icons-material/Numbers';
import EuroIcon from '@mui/icons-material/Euro';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContactsIcon from '@mui/icons-material/Contacts';
import DocumentCreateDescription from './DocumentCreateDescription';

// Prázdny záznam (predvolený)
const EmptySource = (): Document => ({
    id: 0,
    documentTypeId: 0,
    departmentId: 0,
    warehouseId: 0,
    stockMovementTypeId: 0,
    done: false,
    paid: false,
    number: '',
    numberPlain: 0,
    numberSequenceId: 0,
    documentStateId: 0,
    paymentId: 0,
    shipmentId: 0,
    trackingNumber: '',
    symbolVariable: '',
    symbolSpecific: '',
    symbolConstant: '',
    subject: '',
    description: '',
    textBefore: '',
    textAfter: '',
    currencyCode: '',
    currencyExchange: 0,
    priceTotalNoVat: 0,
    priceTotalVatAmount: 0,
    pricePurchaseTotal: 0,
    priceStockTotal: 0,
    customer: {},
    owner: {},
    items: [],
    notes: [],
    payments: [],
    history: []
});

// Spoločné vstupné parametre pre vnorené komponenty (aby som nemusel pri každom rozpisovať všetko spoločné)
export interface DocumentCreateComponentProps {
    open: boolean;
    source: Document;
    documentType: DocumentType;
    vatRates: number[];
    vatRateDefault: number;
    vatCalculationMethod: number;
    priceIncludeVat: boolean;
    customerDiscount: number;
    customerId: number;
    warehouseId: number;
    languageDefaultId: number;
    priceList: PriceList;
    currency: Currency;
    autoFocus?: string;
    setSource: (action: React.SetStateAction<Document>) => void;
    setFocus: (input: any) => void;
    onChange: (property: string, value: any) => void;
    setLoading?: (active: boolean) => void;
};

// Možnosti pri uložení
export enum DocumentCreateSaveAction {
    CancelAndNew,
    SaveAndContinue,
    SaveAndNew,
    SaveAndNewFromCopy,
    SaveAndPrint
}

// Vstupné parametre
export interface DocumentCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    document?: Document; // Predvyplnený doklad, ktorý sa aplikuje na emptySource
    documentType: DocumentType;
    documentTypes: DocumentType[];
    departmentId?: number; // Predvyplnené oddelenie
    warehouseId?: number; // Predvyplnený sklad (pri príjemke / výdajke nie je možná zmena)
    autoFocus?: string;
    keepMounted?: boolean;
    disableSaveAction?: boolean;        // Vypne rozšírené akcie po uložení
    onSave?: (id?: number, action?: DocumentCreateSaveAction) => void;
    onClose: () => void;
}

const DocumentCreate = (props: DocumentCreateProps) => {

    // Globálne premenné
    const appContext = useContext(AppContext);

    // Skladový pohyb (príjem / výdaj)
    const isStockMovement = (props.documentType.stock ?? 0) === DocumentTypeStockUsages.Inbound || (props.documentType.stock ?? 0) === DocumentTypeStockUsages.Outbound;

    // Povinný výber pre nový pohyb (musím použiť callback kvôli kontextu)
    const [documentCreateMovementCallback, setDocumentCreateMovementCallback] = useState<Document | undefined>(undefined);
    const [documentCreateMovement, setDocumentCreateMovement] = useState<DocumentCreateMovementProps>({
        open: false,
        keepMounted: true,
        warehouses: [],
        departments: [],
        documentType: {},
        stockMovementTypes: [],
        onSelect: (departmentId: number, warehouseId: number, stockMovementType: StockMovementType) => {
            setDocumentCreateMovementCallback({
                departmentId: departmentId,
                warehouseId: warehouseId,
                stockMovementTypeId: stockMovementType.id
            })
        },
        onClose: (cancel) => {
            setDocumentCreateMovement(prev => ({ ...prev, open: false }));
            if (cancel === true)
                props.onClose();
        }
    });
    useEffect(() => {
        if (documentCreateMovementCallback === undefined)
            return;
        setSource(prev => ({
            ...prev,
            stockMovementTypeId: documentCreateMovementCallback.stockMovementTypeId,
            departmentId: documentCreateMovementCallback.departmentId,
            warehouseId: documentCreateMovementCallback.warehouseId,
            priceReload: props.document?.priceReload // Ak bol požadovaný prepočet, je potrebné ho vykonať znovu po výbere skladu (kvôli VNC)
        }));
        handleDepartmentChanged(documentCreateMovementCallback.departmentId ?? 0);
    }, [documentCreateMovementCallback]);

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<string | undefined>(undefined);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [tabValue, setTabValue] = useState<string>('items');
    const [source, setSource] = useState<Document>(EmptySource());
    const [volumeDiscountsLastUsed, setVolumeDiscountsLastUsed] = useState<number | undefined>(undefined); // naposledy použitá zľava, zabraňuje neželanej opakovanej kontrole
    const [saveMenuEl, setSaveMenuEl] = useState<null | HTMLElement>(null);
    const [customerBaseAction, setCustomerBaseAction] = useState<undefined | CustomerBaseComponentAction>(undefined);

    // Zákazník
    const [customerDiscount, setCustomerDiscount] = useState<number>(0); // aktuálna zľava zákazníka, premieta sa automaticky na ceny nových položiek
    const [customerNewSelected, setCustomerNewSelected] = useState<number>(0); // používa sa pre automatické vyvolanie zmien po výbere NOVÉHO partnera, aby sa nevyvolali pri naplnení source.customer.customerId 

    // Sledovanie zmien - aktivované oneskorene, aby sa stihol zmeniť stav "source"
    const [sourceChanged, setSourceChanged] = useState<boolean | undefined>(undefined);
    const setSourceStartWatch = () => { setTimeout(() => { setSourceChanged(false); }, 250); };
    const setSourceStopWatch = () => { setSourceChanged(undefined); };
    useEffect(() => {
        if (sourceChanged === false) {
            setSourceChanged(true);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Stav číselníky
    const [domains, setDomains] = useState<Domain[]>([]);
    const [documentStates, setDocumentStates] = useState<DocumentState[]>([]);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [shipments, setShipments] = useState<Shipment[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [currency, setCurrency] = useState<Currency>({}); // Hlavná systémová mena
    const [countries, setCountries] = useState<Country[]>([]);
    const [parameters, setParameters] = useState<Parameter[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [vatRates, setVatRates] = useState<number[]>([]);
    const [vatRateDefault, setVatRateDefault] = useState<number>(0);
    const [vatCalculationMethod, setVatCalculationMethod] = useState<number>(0);
    const [priceIncludeVat, setPriceIncludeVat] = useState<boolean>(false);
    const [languageDefaultId, setLanguageDefaultId] = useState<number>(0);
    const [tagDiscount, setTagDiscount] = useState<number>(0);
    const [volumeDiscounts, setVolumeDiscounts] = useState<VolumeDiscount[]>([]);
    const [priceLists, setPriceLists] = useState<PriceList[]>();
    const [priceList, setPriceList] = useState<PriceList>();
    const [externalDocumentTypes, setExternalDocumentTypes] = useState<ExternalDocumentType[]>([]);
    const [numberSequences, setNumberSequences] = useState<NumberSequence[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [departmentsGrouped, setDepartmentsGrouped] = useState<any[]>([]);
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [warehousesFiltered, setWarehousesFiltered] = useState<Warehouse[]>([]);
    const [stockMovementTypes, setStockMovementTypes] = useState<StockMovementType[]>([]);
    const [stockMovementTypeName, setStockMovementTypeName] = useState('');

    // Názov skladu
    const warehouseName = useMemo<string>(() => warehouses?.find(warehouse => warehouse.id === source.warehouseId)?.name ?? '', [warehouses, source.warehouseId]);

    // Automaticky zistím názov druhu pohybu
    useEffect(() => {
        let name = stockMovementTypes.find(item => item.id === source.stockMovementTypeId)?.name ?? '';
        if (stockMovementTypeName !== name)
            setStockMovementTypeName(name);
    }, [source.stockMovementTypeId, stockMovementTypes]);

    // Načítanie účtovnej jednotky
    const [ownerToLoad, setOwnerToLoad] = useState<number>(0);
    useEffect(() => {
        if (ownerToLoad === 0)
            return;
        var ownerId = ownerToLoad;
        setOwnerToLoad(0);

        // Ak sa nezmenila, tak nepokračujem
        if (source.owner?.customerId === ownerId)
            return;

        axios
            .get(AppConfig.ApiUri + 'customer/' + ownerId)
            .then(response => {
                if (response.data !== null) {
                    var customer = response.data as Customer;
                    onCustomerOrOwnerSave(CustomerBaseFromCustomer(customer), customer, false);
                }
            });
    }, [ownerToLoad]);

    // Udalosť po zmene strediska pre načítanie ÚJ (nemôžem použiť háčik na parameter, aby sa udalosť nevyvolala vyplnení pri edite)
    const handleDepartmentChanged = (departmentId: number) => {
        if (departmentId === 0)
            return;
        let ownerId = departments.find(department => department.id === departmentId)?.customerId ?? 0;
        if (ownerId === 0)
            return;
        setOwnerToLoad(ownerId);
    }

    // Automaticky odfiltrujem sklady z ktorých môžem vyberať (ak pre daný druh dokladu nie sú povolené, tak je zoznam prázdny)
    useEffect(() => {

        // Ak sa nejedná o žiadny druh skladového pohybu, tak sklady nezobrazujem
        if ((props.documentType.stock ?? 0) === DocumentTypeStockUsages.None) {
            setWarehousesFiltered([]);
            return;
        }

        // Odfiltrovaný zoznam
        let filtered: Warehouse[] = [...warehouses];

        // Ak je vybrané stredisko, tak môžem zobraziť len sklady, ktoré patria pod dané stredisko
        if (filtered.length > 0 && (source.departmentId ?? 0) > 0) {
            filtered = filtered.filter(warehouse => (warehouse.departments ?? []).includes((source.departmentId ?? 0)));
        }

        // Ak je nastavený druh dokladu so skladovým pohybom príjem alebo výdaj, tak môžem zobraziť len vlastné sklady
        if (filtered.length > 0 && isStockMovement) {
            filtered = filtered.filter(warehouse => (warehouse.type ?? 0) === 0);
        }

        // Ak mám nastavený sklad, ktorý už nefiguruje v zozname, tak ho musím zrušiť (toto platí len ak už bol načítaný zoznam všetkých skladov)
        if (warehouses.length > 0) {
            setSource(prev => {
                if ((prev.warehouseId ?? 0) > 0 && filtered.find(warehouse => warehouse.id === prev.warehouseId) === undefined) {
                    return { ...prev, warehouseId: 0 };
                }
                return prev;
            });
        }

        setWarehousesFiltered(filtered);

    }, [warehouses, source.departmentId, props.documentType]);

    // Automaticky získam zoznam prevádzok zo stredísk
    useEffect(() => {
        var items: any[] = [];
        var branchNames = Array.from(new Set(departments?.map(item => item.branchName ?? '') ?? []));
        branchNames?.forEach(branchName => {
            items.push(<ListSubheader key={branchName} component="div" disableSticky sx={{ maxHeight: '25px', lineHeight: '25px', background: 'none', fontSize: 'small' }}>{branchName}</ListSubheader>);
            departments?.filter(item => item.branchName === branchName)?.forEach(item => items.push(<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>));
        });
        setDepartmentsGrouped(items);
    }, [departments]);

    // Stav harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleExpandAccordion = (name: string) => {
        if (!isAccordionExpanded(name)) {
            handleToggleAccordion(name);
        }
    };
    const handleToggleAccordion = (name: string, single?: boolean) => {
        if (single === undefined || single === true) {
            if (isAccordionExpanded(name)) {
                setAccordionExpanded([]);
            } else {
                setAccordionExpanded([name]);
            }
        }
        else {
            if (isAccordionExpanded(name)) {
                setAccordionExpanded(prev => prev.filter(item => item !== name));
            } else {
                setAccordionExpanded(prev => [...prev, name]);
            }
        }
    };

    // Funkcia pre získanie predvoleného strediska
    const departmentIdDefault = (): number => {
        // Použijem stredisko na vstupe
        if (props.departmentId !== undefined && props.departmentId > 0) {
            return props.departmentId;
        }
        // Ak je na vstupe sklad, tak použijem prvé stredisko v poradí, pod ktoré patrí daný sklad
        if ((props.warehouseId ?? 0) > 0) {
            return departments?.find(department => warehouses.find(warehouse => warehouse.id === props.warehouseId && warehouse.departments?.includes(department.id ?? 0)))?.id ?? 0;
        }
        // Prvé stredisko v poradí
        return departments[0]?.id ?? 0;
    };

    // Reset formuláru
    const handleReset = (keepId?: boolean) => {
        setCustomerDiscount(0);
        setCustomerNewSelected(0);
        setVolumeDiscountsLastUsed(undefined);
        setSource(prev => {
            let empty = {
                ...EmptySource(), // ako prvé
                id: (keepId ?? false) === true ? prev.id : 0,
                departmentId: departmentIdDefault(),
                warehouseId: props.warehouseId ?? 0,
                documentStateId: documentStates[0]?.id ?? 0,
                shipmentId: shipments[0]?.id ?? 0,
                paymentId: payments[0]?.id ?? 0,
                currencyCode: (currencies.find(currency => currency.system === true)?.code ?? ''),
                currencyExchange: 1,
                ...props.document ?? {}, // na koniec, pred vynútené parametre
                documentTypeId: props.documentType.id ?? 0
            } as Document;

            // Automaticky predvyplním dátum (ak je nastavené 0 a viac dní, -1 je vypnuté)
            if ((props.documentType.fillDateOfIssue ?? -1) >= 0) {
                empty.dateOfIssue = addDays(new Date(), (props.documentType.fillDateOfIssue ?? 0));
            }
            if ((props.documentType.fillDateOfTaxLiability ?? -1) >= 0) {
                empty.dateOfTaxLiability = addDays(new Date(), (props.documentType.fillDateOfTaxLiability ?? 0));
            }
            if ((props.documentType.fillDateOfDue ?? -1) >= 0) {
                empty.dateOfDue = addDays(new Date(), (props.documentType.fillDateOfDue ?? 0));
            }
            if ((props.documentType.fillDateOfDelivery ?? -1) >= 0) {
                empty.dateOfDelivery = addDays(new Date(), (props.documentType.fillDateOfDelivery ?? 0));
            }
            if ((props.documentType.fillDateOfExpiration ?? -1) >= 0) {
                empty.dateOfExpiration = addDays(new Date(), (props.documentType.fillDateOfExpiration ?? 0));
            }
            return empty;
        });
        setSourceStartWatch();
    };
    const handleResetConfirm = (keepId?: boolean) => {
        setConfirm(prev => ({
            ...prev, open: true, title: 'Reset', children: 'Skutočne chcete zrušiť všetky vykonané zmeny?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                loadData();
            }
        }));
    }

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {

        // Po zatvorení okna upravím zobrazenie a ukončím sledovanie zmien
        if (!props.open) {
            if (tabValue !== 'items') {
                setTabValue('items');
            }

            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded(['basic']);
            setSourceStopWatch();
            return;
        }

        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        handleReset();

        // Nový záznam
        if ((props.id ?? 0) === 0) {
            // Pri resetovaní sa použije predvolené stredisko, vyvolám teda udalosť po zmene strediska, aby sa pre nový záznam automaticky dosadila účtovná jednotka (pri edite sa nevolá, keďže sa musí zachovať, preto sa nevyvolá)
            handleDepartmentChanged(departmentIdDefault());

            // Nový príjem alebo výdaj
            if (isStockMovement) {
                setDocumentCreateMovement(prev => ({ ...prev, open: true }));
            }

            return;
        }

        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'document/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    let document = response.data as Document;

                    // Nedovolím zobraziť editáciu cudzieho typu dokladu
                    if ((document?.documentTypeId ?? 0) !== props.documentType?.id) {
                        props.onClose();
                        return;
                    }

                    // Naplním zdroj, keďže potrebujem zachovať pôvodné hodnoty, tak "deep copy" robím až vnútri zmeny stavu
                    setSource(prev => {
                        // Pri kópii zlúčim pôvodnú "empty" a triedu kópie, aby sa zachovali predvolené hodnoty z "empty"
                        if (props.copy === true) {
                            document = ({
                                ...prev, ...DocumentCopy({
                                    documents: [document],
                                    documentTypes: props.documentTypes,
                                    userName: (appContext.userName ?? '')
                                })
                            });
                        }
                        return document;
                    });

                    // Nový príjem alebo výdaj (kópia)
                    if (props.copy === true && isStockMovement) {
                        setDocumentCreateMovement(prev => ({ ...prev, open: true }));
                    }

                    setSourceStartWatch();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Automaticky naplním vybraný cenník, ak sa zmení cenník alebo naplní zoznam cenníkov (cenníky v zozname obsahujú aj množstevné zľavy)
    useEffect(() => {
        if ((source.priceListId ?? 0) === 0 || (priceLists?.length ?? 0) === 0) {
            setPriceList(undefined);
            return;
        }
        setPriceList(priceLists?.find(item => item.id === source.priceListId));
    }, [source.priceListId, priceLists]);

    // Ak generujem nový doklad podľa ručne podhodeného dokladu a ten nemá nastavený stav = undefined, tak ho doplním automaticky po naplnení číselníku (v čase handleReset pri otvorení okna ešte nie je naplnený)
    // Aj nula znamená nastavený stav = žiadny, preto to reaguje na undefined
    useEffect(() => {
        if (props.document !== undefined && source.documentStateId === undefined && documentStates.length > 0 && (documentStates[0].documentTypeId ?? 0) === (props.documentType.id ?? 0)) {
            setSource(prev => ({ ...prev, documentStateId: documentStates[0]?.id ?? 0 }));
        }
    }, [documentStates, source.documentStateId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre naplnenie stavu číselníkov
    const applyFormData = (data: any) => {
        if (data.domain) {
            setDomains(data.domain);
        }
        if (data.documentstate) {
            setDocumentStates(data.documentstate);
        }
        if (data.payment) {
            setPayments(data.payment);
        }
        if (data.shipment) {
            setShipments(data.shipment);
        }
        if (data.currency) {
            setCurrencies(data.currency);
        }
        if (data.pricelist) {
            setPriceLists(data.pricelist);
        }
        if (data.country) {
            setCountries(data.country);
        }
        if (data.parameter) {
            setParameters(data.parameter);
        }
        if (data.unit) {
            setUnits(data.unit);
        }
        if (data.vatrate) {
            setVatRates(data.vatrate);
        }
        if (data.vatratesettings) {
            setVatRateDefault(data.vatratesettings.defaultVatRate ?? 0);
            setVatCalculationMethod(data.vatratesettings.calculationVatMethod ?? 0);
        }
        if (data.pricelistsettings) {
            setPriceIncludeVat(data.pricelistsettings.priceIncludeVat ?? false);
        }
        if (data.languagedefault) {
            setLanguageDefaultId(data.languagedefault ?? 0);
        }
        if (data.tagdiscount) {
            setTagDiscount(data.tagdiscount ?? 0);
        }
        if (data.volumediscount) {
            setVolumeDiscounts(data.volumediscount);
        }
        if (data.externaldocumenttypes) {
            setExternalDocumentTypes(data.externaldocumenttypes);
        }
        if (data.numbersequences) {
            setNumberSequences(data.numbersequences);
        }
        if (data.department) {
            setDepartments(data.department);
        }
        if (data.warehouse) {
            setWarehouses(data.warehouse);
        }
        if (data.stockmovementtype) {
            setStockMovementTypes(data.stockmovementtype);
        }
    };

    // Načítam dáta pre formulár jedným volaním
    const loadFormData = () => {
        if ((props.documentType.id ?? 0) === 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'document/formdata', {
                params: {
                    'documentTypeId': props.documentType.id
                }
            })
            .then(response => {
                if (response.data) {
                    // Aplikujem
                    applyFormData(response.data);
                }
            });
    };
    useEffect(() => loadFormData(), [props.documentType.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // História
    const handleChangeHistory = (property: string, value: any) => {
        // Pozor! 
        // V DEBUG režime sa kvôli render vyvoláva setTimeout 2x, preto sa stav zapíše dvojmo, v produkcii je to OK.
        if (property === 'documentStateId') {
            handleChangeHistorySave('Zmena stavu', (appContext.userName ?? '') + ' zmenil stav dokladu na: ' + (documentStates.find(state => state.id === value)?.other?.translationName ?? '') + '.');
            return;
        }
        if (property === 'paymentId') {
            handleChangeHistorySave('Zmena platby', (appContext.userName ?? '') + ' zmenil spôsob platby na: ' + (payments.find(payment => payment.id === value)?.other?.translationName ?? '') + '.');
            return;
        }
        if (property === 'shipmentId') {
            handleChangeHistorySave('Zmena dopravy', (appContext.userName ?? '') + ' zmenil spôsob dopravy na: ' + (shipments.find(shipment => shipment.id === value)?.other?.translationName ?? '') + '.');
            return;
        }
        if (property === 'done') {
            handleChangeHistorySave('Vybavené', (appContext.userName ?? '') + ' označil doklad ako ' + (value === false ? 'ne' : '') + 'vybavené.');
            return;
        }
        if (property === 'paid') {
            handleChangeHistorySave('Uhradené', (appContext.userName ?? '') + ' označil doklad ako ' + (value === false ? 'ne' : '') + 'uhradené.');
            return;
        }
        if (property === 'items-add') {
            handleChangeHistorySave('Nová položka', (appContext.userName ?? '') + ' pridal položku: ' + value + '.');
            return;
        }
        if (property === 'items-update') {
            handleChangeHistorySave('Zmena položky', (appContext.userName ?? '') + ' upravil položku: ' + value + '.');
            return;
        }
        if (property === 'items-update-list') {
            handleChangeHistorySave('Zmena položiek', (appContext.userName ?? '') + ' upravil položky: ' + value + '.');
            return;
        }
        if (property === 'items-delete') {
            handleChangeHistorySave('Vymazanie položky', (appContext.userName ?? '') + ' vymazal položku: ' + value + '.');
            return;
        }
        if (property === 'owner-update') {
            handleChangeHistorySave('Zmena partnera', (appContext.userName ?? '') + ' upravil ' + ((props.documentType.sale ?? false) === true ? 'dodávateľa' : 'odberateľa') + ': ' + value + '.');
            return;
        }
        if (property === 'customer-update') {
            handleChangeHistorySave('Zmena partnera', (appContext.userName ?? '') + ' upravil ' + ((props.documentType.sale ?? false) === true ? 'odberateľa' : 'dodávateľa') + ': ' + value + '.');
            return;
        }
    };
    const handleChangeHistorySave = (name: string, note: string) => {
        setSource(prev => ({
            ...prev, history: [...prev.history ?? [], {
                createdDate: new Date(),
                name: name,
                note: note
            }]
        }));
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        // Zmena strediska - nedovolím vybrať stredisko v ktorom nefiguruje aktuálny sklad (zmena skladu nie je povolená)
        if (property === 'departmentId' && isStockMovement && (source.warehouseId ?? 0) > 0) {
            var departmentId = value as number;
            var warehouse = warehouses.find(warehouse => warehouse.id === source.warehouseId && warehouse.departments?.includes(departmentId));
            if (warehouse === undefined) {
                setWarning('Vo vybranom stredisku nefiguruje aktuálny sklad, zmena preto nie je povolená!');
                return;
            }
        }
        // Zmena strediska
        if (property === 'departmentId') {
            handleDepartmentChanged((value ?? 0) as number);
        }
        // Zápis histórie
        handleChangeHistory(property, value);
        // Zmena
        setSource(prev => {
            const item: Document = ({ ...prev, [property]: value });
            // Automatická úprava po zmene stavu
            if (property === 'documentStateId') {
                // Ak má vybraný stav dokladu označenú možnosť "označiť ako vybavené", tak automaticky označím vybavené = áno
                if ((documentStates.find(state => state.id === value)?.markAsDone ?? false) === true && (item['done'] ?? false) === false) {
                    item['done'] = true;
                    setTimeout(() => { handleChangeHistory('done', true); }, 500);
                }
                // Ak má vybraný stav dokladu označenú možnosť "označiť ako uhradené", tak automaticky označím uhradené = áno
                if ((documentStates.find(state => state.id === value)?.markAsPaid ?? false) === true && (item['paid'] ?? false) === false) {
                    item['paid'] = true;
                    setTimeout(() => { handleChangeHistory('paid', true); }, 500);
                }
            }
            // Automatická úprava po zmene meny
            if (property === 'currencyCode') {
                // Po zmene meny automaticky upravím kurz
                item['currencyExchange'] = currencies.find(currency => currency.code === value)?.exchange ?? 1;
            }
            return item;
        });
    }

    // Ak sa zmení číselník meny, tak automaticky získam hlavnú systémovú menu
    useEffect(() => {
        setCurrency((currencies.find(currency => currency.system === true) ?? {}));
    }, [currencies]);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning !== undefined) {
            setWarning(undefined);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Funkcia pre vygenerovanie spoločných vstupných parametrov pre komponenty (aby som nemusel pri každom rozpisovať všetko spoločné, premenné hodnoty musím doplniť v rámci render)
    const componentProps = (): DocumentCreateComponentProps => {
        return {
            open: props.open,
            documentType: props.documentType,
            source: {},
            vatRates: [],
            vatRateDefault: 0,
            vatCalculationMethod: 0,
            priceIncludeVat: false,
            languageDefaultId: 0,
            customerDiscount: 0,
            customerId: 0,
            warehouseId: 0,
            priceList: {},
            currency: {},
            autoFocus: props.autoFocus,
            setSource: setSource,
            setFocus: setFocus,
            onChange: handleChange,
            setLoading: setLoading
        }
    };

    // Nastavenie prenosu daňovej povinnosti pre všetky položky (produkt, platba, doprava)
    const handleItemsVatMode = (priceVatMode: DocumentItemPriceVatModes) => {
        // Výnimky
        // --------------------------
        // Ak sa v položkách nachádza zľava s absolútnou hodnotou, tak je potrebné ju prepočítať na cenu bez / s DPH (percentuálne sú ok)
        // Toto riešenie nemusí byť vždy správne, keďže nie všetky položky musia byť v režime PDP (teda s nulovou sadzbou), vtedy je potrebné upozorniť pre ručnú kontrolu a korekciu
        // Zľava sa zadáva vždy z celkovej ceny, bez informácie o výške DPH (alikvotne sa rozpočítava medzi jednotlivé sadzby) a preto sa bude prepočítavať vždy pomocou najvyššej nájdenej sadzby

        // Funkcia pre zistenie, či je potrebné vykonať prepočet absolútnej zľavy
        const isUsedAbsoluteDiscount = (items?: DocumentItem[]): boolean =>
        (
            // V zozname sa nachádza zľava s absolútnou hodnotou
            items?.find(item => item.type === DocumentItemTypes.Discount) !== undefined &&

            // V zozname je položka s nastavenou sadzbou DPH (potrebná pre prepočet)
            items?.find(item => item.type !== DocumentItemTypes.Discount && item.type !== DocumentItemTypes.DiscountRelative && (item.priceVatRate ?? 0) > 0) !== undefined
        );

        // Funkcia pre získanie najvyššej použitej sadzby DPH
        const getMaxVatRate = (items: DocumentItem[]): number => Math.max(...items.map(item => item.priceVatRate ?? 0));

        // Funkcia pre aplikovanie režimu DPH
        const applyItemsVatMode = () => {
            setSource(prev => {

                // Aktuálne položky
                let items = (prev.items ?? []);

                // Zistím či je potrebné prepočítať absolútne zľavy a ak áno, tak získam najvyššiu sadzbu DPH
                const recomputeDiscount = isUsedAbsoluteDiscount(items);
                const recomputeDiscountVatRate = (recomputeDiscount === true ? getMaxVatRate(items) : 0);

                // Upravím všetky položky
                items = items.map(item => {

                    // Musím vytvoriť novú inštanciu položky
                    var item = { ...item };

                    // Prepočítam obsolútnu zľavu
                    if (item.type === DocumentItemTypes.Discount && recomputeDiscount === true) {
                        item.price = priceVatMode === DocumentItemPriceVatModes.Standard ?
                            // Nahor - zmena na bežný režim
                            MathRound4((item.price ?? 0) * ((100 + recomputeDiscountVatRate) / 100)) :
                            // Nadol - zmena na reverse charge (bez DPH)
                            MathRound4((item.price ?? 0) / ((100 + recomputeDiscountVatRate) / 100));
                    }

                    // Pri zľavách nemením režim DPH
                    if (item.type === DocumentItemTypes.Discount || item.type === DocumentItemTypes.DiscountRelative) {
                        return item;
                    }

                    // Nastavím prenos daňovej povinnosti a prepočítam
                    item.priceVatMode = priceVatMode;
                    item = DocumentItemCompute(item);
                    return item;
                });

                // Prepočítam zľavy z položiek
                items = DocumentItemsDiscountCompute(items);

                // Vrátim upravenú triedu
                return ({ ...prev, items: items });
            });
        }

        // Zobrazím upozornenie s možnosťou zrušenia
        if (isUsedAbsoluteDiscount(source.items)) {
            setConfirm(prev => ({
                ...prev, open: true,
                title: 'Zmena režimu DPH',
                children: 'V doklade sa nachádza zľava (fixná suma) z celkovej sumy s DPH. Želáte si pokračovať a zľavu prepočítať ' + (priceVatMode === DocumentItemPriceVatModes.Standard ? 'nahor' : 'nadol') + ' najvyššou použitou sadzbou DPH?',
                onConfirm: () => {
                    setConfirm(prev => ({ ...prev, open: false }));
                    applyItemsVatMode();
                }
            }));
            return;
        }

        // Vyvolám zmenu bez upozornenia
        applyItemsVatMode();
    };

    // Vložiť položku do zoznamu
    const handleItemAdd = (item: DocumentItem, itemIndex?: number) => {
        handleChangeHistory((itemIndex !== undefined && itemIndex >= 0 ? 'items-update' : 'items-add'), item.name);
        setSource(prev => {
            if (itemIndex !== undefined && itemIndex >= 0) {
                // Aktualizácia existujúceho záznamu
                const items = [...prev.items ?? []];
                items[itemIndex] = { ...item };
                return ({ ...prev, items: DocumentItemsDiscountCompute([...items ?? []]) });
            }
            else {
                // Nový záznam (automaticky doplním poradové číslo pre produkt +1)
                if (item.type === DocumentItemTypes.Product && (item.ordinalNumber ?? 0) === 0 && (prev.items?.length ?? 0) > 0) {
                    item.ordinalNumber = (Math.max(...(prev.items?.map(i => i.ordinalNumber ?? 0) ?? [])) ?? 0) + 1;
                }
                return ({ ...prev, items: DocumentItemsDiscountCompute([...prev.items ?? [], item]) });
            }
        });
    };

    // Vymazať položku zo zoznamu
    const handleItemDelete = (index: number) => {
        let itemName = (source.items ?? [])[index]?.name ?? '';
        handleChangeHistory('items-delete', itemName);
        setSource(prev => {
            const items = prev.items ?? [];
            return ({ ...prev, items: DocumentItemsDiscountCompute([...items.filter(item => item !== items[index]) ?? []]) });
        });
    };

    // Vymazať položku zo zoznamu
    const handleItemListDelete = (indexList: number[]) => {
        if (indexList.length === 0) {
            return;
        }
        setSource(prev => {
            const items = prev.items ?? [];
            const deleted: any[] = [...indexList.map((index) => items[index])];
            const changed = [...items.filter(row => !deleted.includes(row)) ?? []];
            return ({ ...prev, items: DocumentItemsDiscountCompute([...changed ?? []]) });
        });
    };

    // Aplikuje zľavu na označené položky
    const handleItemListDiscount = (indexList: number[], discount: number) => {
        if (indexList.length === 0) {
            return;
        }
        handleChangeHistory('items-update-list', 'Zľava na položku: ' + discount + ' %');
        setSource(prev => {
            const items = prev.items ?? [];
            for (var index = 0; index < items.length; index++) {
                if (indexList.includes(index)) {
                    let item = items[index];
                    if (item.type === DocumentItemTypes.Discount ||
                        item.type === DocumentItemTypes.DiscountRelative) {
                        continue; // zľavu na položku nemôžem vložiť na položku typu zľava
                    }
                    item.priceDiscount = discount;
                    items[index] = { ...DocumentItemCompute(item) };
                }
            }
            return ({ ...prev, items: DocumentItemsDiscountCompute([...items ?? []]) });
        });
    };

    // Uloženie formuláru
    const handleSave = (event?: FormEvent<HTMLFormElement>, action?: DocumentCreateSaveAction) => {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }

        // Druh dokladu - povinné
        if ((source.documentTypeId ?? 0) === 0) {
            setWarning('Neznámy druh dokladu!');
            return;
        }

        // Stredisko - povinné
        if ((source.departmentId ?? 0) === 0) {
            setWarning('Musíte vybrať stredisko!');
            return;
        }

        // Skladový príjem alebo výdaj
        if (isStockMovement) {

            // Druh príjmu alebo výdaja (tuzemsko, zahraničie, príjem z iného skladu)
            if ((source.stockMovementTypeId ?? 0) === 0) {
                setWarning('Neznámy druh skladového pohybu!');
                return;
            }

            // Sklad - povinné pre akýkoľvek druh príjmu alebo výdaja
            if ((source.stockMovementTypeId ?? 0) > 0 && (source.warehouseId ?? 0) === 0) {
                setWarning('Musíte vybrať sklad!');
                return;
            }
        }

        // Skontrolujem položky
        if ((source.items?.filter(item => item.type === 0)?.length ?? 0) === 0) {
            setWarning('Musíte vložiť položky!');
            return;
        }

        // Dátum vystavenia (ak je zobrazený) - pre skladový pohyb je zobrazený vždy!
        if (((props.documentType.hideDateOfIssue ?? false) === false || isStockMovement) && NullMinDate(source.dateOfIssue) === null) {
            setWarning('Musíte vyplniť dátum vystavenia!');
            return;
        }

        setWarning(undefined);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'document', source)
            .then(response => {
                if (response.data !== undefined) {
                    const responseData = response.data as ResponseData
                    if (!responseData.success) {
                        setWarning('Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.');
                        return;
                    }
                    props.onClose();
                    if (props.onSave) {
                        props.onSave((responseData.data ?? 0) as number, action);
                    }
                }
            })
            .catch(() => {
                setWarning('Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické zistenie aktuálnej zľavy partnera po jeho zmene (aj pri editácii)
    useEffect(() => {
        setCustomerDiscount(0);
        if ((source.customer?.customerId ?? 0) === 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'customer/discount/' + (source.customer?.customerId ?? 0))
            .then(response => {
                if (response.data !== null && response.data > 0) {
                    setCustomerDiscount(response.data as number);
                }
            });
    }, [source.customer?.customerId]);

    // Automatické zistenie cenníka vybraného nového partnera (nevyvolá sa pri edite, kde už spätne nemôžem zmeniť vybraný cenník na existujúcom doklade, iba ak by som nanovo vybral partnera)
    useEffect(() => {
        if ((customerNewSelected ?? 0) === 0) {
            return;
        }
        // Získam cenník v poradí podľa priority: nastavenie zákazníka, nastavenie zákazníckej skupiny zákazníka, nastavenie východzieho cenníku domény zákazníka (rieši priamo API)
        axios
            .get(AppConfig.ApiUri + 'pricelist/customer/' + (customerNewSelected ?? 0))
            .then(response => {
                if (response.data !== null) {
                    setSource(prev => ({ ...prev, priceListId: (response.data as PriceList).id ?? prev.priceListId ?? 0 }));
                }
            });
    }, [customerNewSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automatická kontrola objemových zliav po každej zmene v položkách (kontrola z celkovej ceny bez aplikovaných zliav)
    // Pomocou volumeDiscountsLastUsed (naposledy použitá zľava) zabránim opakovanej neželanej kontrole pri:
    // - otvorení existujúceho dokladu (source.id > 0)
    // - otvorení ručne vyplneného dokladu s položkami (props.document !== undefined)
    // - ručnom zmazaní objemovej zľavy, kde nechcem aby sa zľava pridala automaticky kým znovu neprekročím inú objemovú hranicu (ak sa ale objemová zľava vymaže automaticky tak sa nemôže blokovať opätovná kontrola)
    useEffect(() => {

        // Ak sú položky prázdne, tak ďalej nepokračujem 
        if ((source?.items?.length ?? 0) === 0) {
            return;
        }

        // Nová objemová zľava
        let volumeDiscount: VolumeDiscount | undefined = undefined;

        // Aktuálna objemová zľava v položkách
        let volumeDiscountItem: DocumentItem | undefined = source?.items?.find(item => (item.code === 'volumediscount' && (item.type === DocumentItemTypes.Discount || item.type === DocumentItemTypes.DiscountRelative)));

        // Pri prvom zobrazení si poznačím aktuálne použitú objemovú zľavu, aby som vedel porovnať, či som zmenil hranicu
        if (volumeDiscountsLastUsed === undefined) {
            setVolumeDiscountsLastUsed(volumeDiscountItem?.price ?? 0);

            // Ďalej pokračujem iba pri novom doklade, keďže som práve vložil prvú položku
            // Ďalej nepokračujem pri: 
            // - otvorení existujúceho dokladu (source.id > 0)
            // - otvorení ručne vyplneného dokladu s položkami (props.document !== undefined)
            // Lebo by som automaticky vložil alebo vyhodil zľavu aj keď mohla byť zmazaná, ručne pridaná, alebo ju v tom čase ešte systém nedopočítal, ak tak sa pridá až pri ďalšej zmene v položkách
            if ((source.id ?? 0) > 0 || props.document !== undefined) {
                return;
            }
        }

        // Zoznam položiek bez objemovej zľavy, pre výpočet celkovej ceny (súčasne zoznam použijem na naplnenie stavu v prípade zmeny, aby som ušetril ďalšiu filtráciu)
        let itemsSource = source?.items?.filter(item => !(item.code === 'volumediscount' && (item.type === DocumentItemTypes.Discount || item.type === DocumentItemTypes.DiscountRelative))) ?? [];

        // Kontrolu vykonám iba ak existujú objemové zľavy a súčasne buď nie je vybraný žiadny zákaznícky cenník prázdny, alebo má vybraný zákaznícky cenník povolené objemové zľavy
        if (volumeDiscounts.length > 0 && ((priceList?.id ?? 0) === 0 || priceList?.allowVolumeDiscount === true)) {
            // Celková suma položiek, pracujem vždy s kladnou hodnotou, keďže na dobropise môže byť aj záporná (nie sú tu zarátané zľavy, skutočné hodnoty (jednotkové ceny) zliav figurujú ako podpoložky zľavových položiek)
            let itemsTotalPrice = Math.abs(itemsSource?.reduce((sum, current) => sum + ((current?.priceTotalNoVat ?? 0) + (current?.priceTotalVatAmount ?? 0)), 0) ?? 0);

            // Zoradím od najväčšej minimálnej ceny a následne nájdem prvú v poradí s podmienkou:
            // - ak je vybraná zákaznícka skupina, tak figurovať v zozname povolených skupín, alebo tento zoznam musí byť prázdny (pre všetko)
            // - ak je vybraná doména, tak figurovať v zozname povolených domén, alebo tento zoznam musí byť prázdny (pre všetko)
            // - musí mať menšiu alebo rovnakú minimálnu sumu ako je celková suma dokladu
            volumeDiscount = volumeDiscounts
                .sort((a, b) => (a.minimalPrice ?? 0) < (b.minimalPrice ?? 0) ? 1 : -1)
                .find(d =>
                    (
                        // - buď je zoznam prázdny (platí pre všetko), alebo musí obsahovať danú zákaznícku skupinu
                        (d.customerGroups?.length ?? 0) === 0 || ((source.customer?.customerGroupId ?? 0) > 0 && d.customerGroups?.includes(source.customer?.customerGroupId ?? 0))
                    )
                    &&
                    (
                        // - buď je zoznam prázdny (platí pre všetko), alebo musí obsahovať danú zákaznícku skupinu
                        (d.domains?.length ?? 0) === 0 || ((source.customer?.domainId ?? 0) > 0 && d.domains?.includes(source.customer?.domainId ?? 0))
                    )
                    && (d.minimalPrice ?? 0) <= itemsTotalPrice && (d.discount ?? 0) > 0);
        }

        // Ak sa nenašla objemová zľava, tak ju odstránim zo zoznamu a ďalej nepokračujem
        if (volumeDiscount === undefined) {

            // Uchovám naposledy použitú hladinu, aby som vykonal zmenu len ak sa zmení
            setVolumeDiscountsLastUsed(0);

            // Odstránim zo zoznamu ale iba vtedy, ak v nom ešte figuruje (mohol som ju medzi časom ručne zmazať)
            if (volumeDiscountItem !== undefined) {
                // Uložím stav s oneskorením, aby sa stihol uložiť stav naposledy použitej hladiny
                setTimeout(() => { setSource(prev => ({ ...prev, items: DocumentItemsDiscountCompute([...itemsSource ?? []]) })); }, 250);
            }
            return;
        }

        // Ak som našiel objemovú zľavu a je iná ako naposledy použitá, tak ju aplikujem
        if (volumeDiscount !== undefined && volumeDiscountsLastUsed !== volumeDiscount.discount) {

            // Uchovám naposledy použitú hladinu, aby som vykonal zmenu len ak sa zmení
            setVolumeDiscountsLastUsed(volumeDiscount.discount);

            // Pridám do položiek
            let items = [
                ...itemsSource,
                {
                    code: 'volumediscount',
                    type: volumeDiscount.type === 0 ? DocumentItemTypes.Discount : DocumentItemTypes.DiscountRelative,
                    price: volumeDiscount.discount,
                    priceIncludeVat: true, // vždy vychádzam z ceny s DPH (musí sedieť celková suma dokladu, teda suma s DPH)
                    name: 'Objemová zľava',
                    quantity: 1,
                    unit: 'ks'
                }
            ];

            // Uložím stav s oneskorením, aby sa stihol uložiť stav naposledy použitej hladiny
            setTimeout(() => { setSource(prev => ({ ...prev, items: DocumentItemsDiscountCompute([...items ?? []]) })); }, 250);
        }
    }, [source.items]); // eslint-disable-line react-hooks/exhaustive-deps

    // Zmena odberateľa alebo dodávateľa
    const onCustomerOrOwnerSave = (customerBase: CustomerBase, customer: Customer | undefined, isCustomer: boolean) => {
        handleChangeHistory(isCustomer === true ? 'customer-update' : 'owner-update', (customerBase.person === true && (customerBase.firstname || customerBase.lastname)) ? customerBase.firstname + ' ' + customerBase.lastname : customerBase.company);
        if (isCustomer === true) {
            setSource(prev => ({ ...prev, customer: customerBase }));
            setCustomerNewSelected(customerBase.customerId ?? 0);

            // Ak bol vybraný nový partner, tak použijem preferované hodnoty
            if (customer !== undefined && (customer?.id ?? 0) > 0 && (source.customer?.customerId ?? 0) !== (customer?.id ?? 0)) {
                applyCustomerPreferredOptions(customer);
            }
        }
        else {
            setSource(prev => ({ ...prev, owner: customerBase }));
        }
    }

    // Použije preferované možnosti, po odsúhlasení (ak existujú)
    const applyCustomerPreferredOptions = (customer: Customer) => {

        // Získam preferované nastavenia (čísleníky musia existovať)
        const shipment = (customer.shipmentId ?? 0) > 0 ? shipments?.find(shipment => shipment.id === customer.shipmentId) : undefined;
        const payment = (customer.paymentId ?? 0) > 0 ? payments?.find(payment => payment.id === customer.paymentId) : undefined;
        const dueDays = (customer.dueDays ?? 0) > 0 && (props.documentType.hideDateOfDue ?? false) === false ? customer.dueDays : undefined;

        // Ak sa nenašli žiadne prefereované nastavenia, tak nepokračujem
        if (shipment === undefined &&
            payment === undefined &&
            dueDays === undefined) {
            return;
        }

        // Zobrazím hlášku
        setConfirm(prev => ({
            ...prev, open: true,
            title: 'Preferované možnosti partnera',
            children:
                <>
                    {shipment !== undefined && <><strong>Doprava</strong>: {shipment.other?.translationName}<br /></>}
                    {payment !== undefined && <><strong>Platba</strong>: {payment.other?.translationName}<br /></>}
                    {dueDays !== undefined && <><strong>Splatnosť (dni)</strong>: {dueDays}<br /></>}
                    <br />
                    Želáte si ich použiť v doklade?
                </>,
            onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setSource(prev => {
                    return ({
                        ...prev,
                        shipmentId: shipment !== undefined ? shipment.id : prev.shipmentId,
                        paymentId: payment !== undefined ? payment.id : prev.paymentId,
                        dateOfDue: dueDays !== undefined ? addDays(NullMinDate(source.dateOfIssue) ?? new Date(), dueDays) : prev.dateOfDue
                    });
                });
            }
        }));
    }

    // Info text v harnominke
    let titleCustomer = '(nie)';
    let titleOwner = '(nie)';
    let titleSummaryAmount = (source.priceTotalNoVat ?? 0) + (source.priceTotalVatAmount ?? 0);
    let titleSummary = FormatCurrency(titleSummaryAmount, currency, 2);

    // Celkom v cudzej mene
    if ((source.currencyCode ?? '') !== '' && source.currencyCode !== currency.code) {
        let foreignCurrency: Currency = {};
        if (source.currencyCode !== '' && source.currencyCode !== currency.code) {
            foreignCurrency = currencies.find(currency => currency.code === source.currencyCode) ?? { surfix: source.currencyCode };
        }
        titleSummary += ' / ' + FormatCurrency((titleSummaryAmount * (source.currencyExchange ?? 1)), foreignCurrency, 2);
    }

    if (source.customer?.person === true && (source.customer.firstname || source.customer.lastname)) {
        titleCustomer = source.customer.firstname + ' ' + source.customer.lastname;
    }
    if (source.customer?.person === false && source.customer.company) {
        titleCustomer = source.customer.company;
    }

    if (source.owner?.person === true && (source.owner.firstname || source.owner.lastname)) {
        titleOwner = source.owner.firstname + ' ' + source.owner.lastname;
    }
    if (source.owner?.person === false && source.owner.company) {
        titleOwner = source.owner.company;
    }

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xl" fullWidth open={props.open} scroll="body" onClose={(e, r) => {
                if (r !== 'backdropClick') {
                    if (sourceChanged !== true) {
                        props.onClose();
                        return;
                    }
                    setConfirm(prev => ({
                        ...prev, open: true, title: 'Neuložené zmeny', children: 'Skutočne chcete zavrieť okno bez uloženia zmien?', onConfirm: () => {
                            setConfirm(prev => ({ ...prev, open: false }));
                            props.onClose();
                        }
                    }));
                }
            }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave} noValidate>
                    <DialogContent sx={{ height: '85vh' }}>
                        <Grid container columnSpacing={3} sx={{ mt: 1 }}>

                            {/* Ľavý panel */}
                            <Grid item xs={12} lg={4}>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('basic')} onChange={() => handleToggleAccordion('basic')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography fontWeight="600">
                                            {props.documentType.other?.translationName} {stockMovementTypeName.length > 0 ? ' - ' + stockMovementTypeName : ''} / {((source.id ?? 0) > 0 && !props.copy ? (source.number ?? '-') : 'Nový doklad')}
                                            {(props.copy && ' (kópia)')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={warehousesFiltered.length > 0 ? 6 : 12}>
                                                <FormControl required margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="document-create-department">Stredisko (oddelenie)</InputLabel>
                                                    <Select labelId="document-create-department" label="Stredisko (oddelenie)" value={source.departmentId ?? 0} name="departmentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => { (props.autoFocus === 'departmentId' || props.autoFocus === 'departmentName') && setFocus(input) }}>
                                                        <MenuItem key={0} value={0}>-</MenuItem>
                                                        {departmentsGrouped.map(item => item)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {warehousesFiltered.length > 0 && (
                                                <Grid item xs={6}>
                                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                                        <InputLabel id="document-create-warehouse">Sklad</InputLabel>
                                                        <Select disabled={isStockMovement} labelId="document-create-warehouse" label="Sklad" value={source.warehouseId ?? 0} name="warehouseId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => { (props.autoFocus === 'warehouseId' || props.autoFocus === 'warehouseName') && setFocus(input) }}>
                                                            <MenuItem key={0} value={0}>-</MenuItem>
                                                            {warehousesFiltered?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}

                                            {(documentStates?.length ?? 0) > 0 && (
                                                <Grid item xs={12}>
                                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                                        <InputLabel id="documentcreate-state">Stav</InputLabel>
                                                        <Select labelId="documentcreate-state" label="Stav" value={source.documentStateId ?? 0} name="documentStateId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'documentStateId' && setFocus(input)}>
                                                            <MenuItem key={0} value={0}>-</MenuItem>
                                                            {documentStates?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}

                                            {(props.documentType.sale ?? false) === true && (
                                                <Grid item xs={12}>
                                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                                        <InputLabel id="documentcreate-pricelist">Cenník</InputLabel>
                                                        <Select labelId="documentcreate-pricelist" label="Cenník" value={source.priceListId ?? 0} name="priceListId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => { props.autoFocus === 'priceListId' && setFocus(input) }}>
                                                            <MenuItem key={0} value={0}>-</MenuItem>
                                                            {priceLists?.map(item => <MenuItem key={item.id} value={item.id}>{item.name + ', ' + ((item.discount ?? 0) * -1) + '%'}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="documentcreate-shipment">Doprava</InputLabel>
                                                    <Select labelId="documentcreate-shipment" label="Doprava" value={source.shipmentId ?? 0} name="shipmentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'shipmentId' && setFocus(input)}>
                                                        <MenuItem key={0} value={0}>-</MenuItem>
                                                        {shipments?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="documentcreate-payment">Platba</InputLabel>
                                                    <Select labelId="documentcreate-payment" label="Platba" value={source.paymentId ?? 0} name="paymentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'paymentId' && setFocus(input)}>
                                                        <MenuItem key={0} value={0}>-</MenuItem>
                                                        {payments?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControlLabel control={<Switch checked={source.done} name="done" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Vybavené" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControlLabel control={<Switch checked={source.paid} name="paid" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Uhradené" />
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('customer')} onChange={() => handleToggleAccordion('customer')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            {((props.documentType.sale ?? false) === true ? 'Odberateľ' : 'Dodávateľ')}{isAccordionExpanded('customer') ? null : <>: <strong>{titleCustomer}</strong></>}
                                            {(props.documentType.sale ?? false) === true && customerDiscount > 0 && <Chip component='span' label={'-' + customerDiscount + '%'} size='small' sx={{ ml: 1 }} />}
                                            {/* Pri výbere akcie musím vždy rozbaliť harmoniku, aby sa pripojil komponent, keďže je aktívne nastavenie: "unmountOnExit" */}
                                            {isAccordionExpanded('customer') === false && <Button onClick={(e) => { e.stopPropagation(); handleExpandAccordion('customer'); setCustomerBaseAction(CustomerBaseComponentAction.Select); }} size='small' color='secondary' sx={{ position: 'absolute', top: 8, right: 45 }} startIcon={<ContactsIcon />}>Vybrať</Button>}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CustomerBaseComponent customer={source.customer ?? {}} countries={countries}
                                            startAction={customerBaseAction}
                                            startActionCallback={() => setCustomerBaseAction(undefined)}
                                            onSave={(customerBase, customer) => {
                                                onCustomerOrOwnerSave(customerBase, customer, true);
                                            }} />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('owner')} onChange={() => handleToggleAccordion('owner')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            {((props.documentType.sale ?? false) === false ? 'Odberateľ' : 'Dodávateľ')}{isAccordionExpanded('owner') ? '' : ': ' + titleOwner}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CustomerBaseComponent customer={source.owner ?? {}} countries={countries}
                                            onSave={(customerBase, customer) => {
                                                onCustomerOrOwnerSave(customerBase, customer, false);
                                            }} />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: false /* Ponechat pripojené, aby vždy bežal prepočet */ }} expanded={isAccordionExpanded('summary')} onChange={() => handleToggleAccordion('summary')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            Sumár{isAccordionExpanded('summary') ? null : <>: {titleSummary}</>}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DocumentCreateSummary {...componentProps()} source={source} vatRates={vatRates} vatCalculationMethod={vatCalculationMethod} currency={currency} currencies={currencies} />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion sx={{ mb: 2 }} TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('details')} onChange={() => handleToggleAccordion('details')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Podrobnosti</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            {(props.documentType.hideSymbols ?? false) === false && (
                                                <>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField margin="dense" name="symbolVariable" label="Variabilný symbol (automaticky)" fullWidth variant="outlined" autoComplete="off" value={source.symbolVariable ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'symbolVariable' && setFocus(input)}
                                                            inputProps={{ maxLength: 10 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <NumbersIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField margin="dense" name="symbolSpecific" label="Špecifický symbol" fullWidth variant="outlined" autoComplete="off" value={source.symbolSpecific ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'symbolSpecific' && setFocus(input)}
                                                            inputProps={{ maxLength: 10 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <NumbersIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField margin="dense" name="symbolConstant" label="Konštantný symbol" fullWidth variant="outlined" autoComplete="off" value={source.symbolConstant ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'symbolConstant' && setFocus(input)}
                                                            inputProps={{ maxLength: 4 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <NumbersIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                </>
                                            )}

                                            {((props.documentType.hideDateOfIssue ?? false) === false || isStockMovement) && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                        <FormControl margin="dense" fullWidth>
                                                            <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Vystavenie" value={NullMinDate(source.dateOfIssue)} onChange={(d) => { handleChange('dateOfIssue', d, 'date') }} inputRef={input => { props.autoFocus === 'dateOfIssue' && setFocus(input) }} />
                                                        </FormControl>
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            {(props.documentType.hideDateOfDue ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                        <FormControl margin="dense" fullWidth>
                                                            <DesktopDatePicker format='dd.MM.yyyy' label="Splatnosť" value={NullMinDate(source.dateOfDue)} onChange={(d) => { handleChange('dateOfDue', d, 'date') }} inputRef={input => { props.autoFocus === 'dateOfDue' && setFocus(input) }} />
                                                        </FormControl>
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            {(props.documentType.hideDateOfTaxLiability ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                        <FormControl margin="dense" fullWidth>
                                                            <DesktopDatePicker format='dd.MM.yyyy' label="Zdaniteľné plnenie" value={NullMinDate(source.dateOfTaxLiability)} onChange={(d) => { handleChange('dateOfTaxLiability', d, 'date') }} inputRef={input => { props.autoFocus === 'dateOfTaxLiability' && setFocus(input) }} />
                                                        </FormControl>
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            {(props.documentType.hideDateOfDelivery ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                        <FormControl margin="dense" fullWidth>
                                                            <DesktopDatePicker format='dd.MM.yyyy' label="Dodanie" value={NullMinDate(source.dateOfDelivery)} onChange={(d) => { handleChange('dateOfDelivery', d, 'date') }} inputRef={input => { props.autoFocus === 'dateOfDelivery' && setFocus(input) }} />
                                                        </FormControl>
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            {(props.documentType.hideDateOfExpiration ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                        <FormControl margin="dense" fullWidth>
                                                            <DesktopDatePicker format='dd.MM.yyyy' label="Platnosť" value={NullMinDate(source.dateOfExpiration)} onChange={(d) => { handleChange('dateOfExpiration', d, 'date') }} inputRef={input => { props.autoFocus === 'dateOfExpiration' && setFocus(input) }} />
                                                        </FormControl>
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            <Grid item xs={12} sm={6}>
                                                <TextField fullWidth margin="dense" name="currencyExchange" type="text" label="Kurz" variant="outlined" autoComplete="off" value={source.currencyExchange ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    disabled={(source.currencyCode === currency.code)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EuroIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="document-create-currency">Mena</InputLabel>
                                                    <Select required labelId="document-create-currency" label="Mena" value={source.currencyCode ?? 0} name="currencyCode" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'currencyCode' && setFocus(input)}>
                                                        {currencies?.map(item => <MenuItem key={item.id} value={item.code}>{item.code}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="document-create-numbersequences">Číselný rad</InputLabel>
                                                    <Select disabled={(source.id ?? 0) > 0} labelId="document-create-numbersequences" label="Číselný rad" value={source.numberSequenceId ?? 0} name="numberSequenceId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'numberSequenceId' && setFocus(input)}>
                                                        <MenuItem value={0}>Automaticky</MenuItem>
                                                        {numberSequences?.map(item => <MenuItem key={item.id} value={item.id}>
                                                            {item.template}
                                                            {(() => {
                                                                const info = [item.departmentName ?? '', item.warehouseName ?? '']
                                                                    .filter(name => name.length > 0)
                                                                    .join(' ');
                                                                return info ? ` (${info})` : '';
                                                            })()}

                                                        </MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {(props.documentType.hideTrackingNumber ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <TextField margin="dense" name="trackingNumber" label="Sledovacie číslo" fullWidth variant="outlined" autoComplete="off" value={source.trackingNumber ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => (props.autoFocus === 'trackingNumber') && setFocus(input)}
                                                        inputProps={{ maxLength: 255 }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <NumbersIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>
                                            )}

                                            <Grid item xs={12} mt={1}>
                                                <Table size="small">
                                                    <TableBody>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ pl: 0 }}>Vytvorené</TableCell>
                                                            <TableCell sx={{ pr: 0 }} align="right">{(source.createdDate !== undefined ? dateFormat(new Date(source.createdDate ?? new Date()), 'dd.MM.yyyy HH:mm') : '-')}</TableCell>
                                                        </TableRow>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ pl: 0 }}>Upravené</TableCell>
                                                            <TableCell sx={{ pr: 0 }} align="right">{(source.updatedDate !== undefined ? dateFormat(new Date(source.updatedDate ?? new Date()), 'dd.MM.yyyy HH:mm') : '-')}</TableCell>
                                                        </TableRow>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ pl: 0 }}>Doména</TableCell>
                                                            <TableCell sx={{ pr: 0 }} align="right">{(source.customer?.domainId ?? 0) > 0 ? domains.find(domain => domain.id === source.customer?.domainId)?.name ?? '' : '-'}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <TextField margin="dense" name="subject" label="Predmet" fullWidth variant="outlined" autoComplete="off" value={source.subject ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'subject') && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />

                                <CustomFieldValues moduleId={Modules.Document} hideTitle={true} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />
                            </Grid>

                            {/* Pravý panel */}
                            <Grid item xs={12} lg>

                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                        <Tab value="items" label={'Položky (' + (source.items?.length ?? 0) + ')'} />
                                        {(props.documentType.hidePayments ?? false) === false && (
                                            <Tab value="payments" label={'Úhrady (' + (source.payments?.length ?? 0) + ')'} />
                                        )}
                                        {(props.documentType.hideDocuments ?? false) === false && (
                                            <Tab value="related" label={'Doklady (' + ((source.relatedParents?.length ?? 0) + (source.relatedChildren?.length ?? 0) + (source.relatedExternal?.length ?? 0)) + ')'} />
                                        )}
                                        {(props.documentType.hideNotes ?? false) === false && (
                                            <Tab value="notes" label={'Poznámky (' + (source.notes?.length ?? 0) + ')'} />
                                        )}
                                        {(props.documentType.hideFiles ?? false) === false && (
                                            <Tab value="files" label={'Prílohy (' + (source.files?.length ?? 0) + ')'} />
                                        )}
                                        <Tab value="description" label={'Popis (' + ((source.description?.length ?? 0) > 0 || (source.textBefore?.length ?? 0) > 0 || (source.textAfter?.length ?? 0) > 0 ? 'áno' : 'nie') + ')'} />
                                        <Tab value="history" label={'História (' + (source.history?.length ?? 0) + ')'} />
                                    </Tabs>
                                </Box>
                                <TabContext value={tabValue}>
                                    <TabPanel value="items" sx={{ px: '0', pb: 0, pt: 1 }}>
                                        <DocumentCreateItems {...componentProps()}
                                            onItemAdd={handleItemAdd}
                                            onItemDelete={handleItemDelete}
                                            onItemListDelete={handleItemListDelete}
                                            onItemListDiscount={handleItemListDiscount}
                                            onItemsVatMode={handleItemsVatMode}
                                            source={source}
                                            units={units}
                                            parameters={parameters}
                                            vatRates={vatRates}
                                            vatRateDefault={vatRateDefault}
                                            vatCalculationMethod={vatCalculationMethod}
                                            priceIncludeVat={priceIncludeVat}
                                            languageDefaultId={languageDefaultId}
                                            customerDiscount={customerDiscount}
                                            customerId={source.customer?.customerId ?? 0}
                                            priceList={priceList ?? {}}
                                            tagDiscount={tagDiscount}
                                            warehouseId={source.warehouseId ?? 0}
                                            warehouses={warehouses}
                                            warehouseName={warehouseName}
                                            payments={payments}
                                            shipments={shipments}
                                            currency={currency} />
                                    </TabPanel>
                                    {(props.documentType.hidePayments ?? false) === false && (
                                        <TabPanel value="payments" sx={{ px: '0', pb: 0, pt: 1 }}>
                                            <DocumentCreatePayments {...componentProps()} payments={payments} source={source} currency={currency} onChangeHistory={handleChangeHistory} />
                                        </TabPanel>
                                    )}
                                    {(props.documentType.hideDocuments ?? false) === false && (
                                        <TabPanel value="related" sx={{ px: '0', pb: 0, pt: 1 }}>
                                            <DocumentCreateRelated {...componentProps()} source={source} documentTypes={props.documentTypes} />
                                            <DocumentCreateRelatedExternal {...componentProps()} source={source} documentTypes={props.documentTypes} externalDocumentTypes={externalDocumentTypes} />
                                        </TabPanel>
                                    )}
                                    {(props.documentType.hideNotes ?? false) === false && (
                                        <TabPanel value="notes" sx={{ px: '0', pb: 0, pt: 1 }}>
                                            <DocumentCreateNotes {...componentProps()} source={source} />
                                        </TabPanel>
                                    )}
                                    {(props.documentType.hideFiles ?? false) === false && (
                                        <TabPanel value="files" sx={{ px: '0', pb: 0, pt: 1 }}>
                                            <DocumentCreateFiles source={source} setSource={setSource} />
                                        </TabPanel>
                                    )}
                                    <TabPanel value="description" sx={{ px: '0', pb: 0, pt: 1 }}>
                                        <DocumentCreateDescription setSource={setSource} source={source} />
                                    </TabPanel>
                                    <TabPanel value="history" sx={{ px: '0', pb: 0, pt: 1 }}>
                                        <DocumentCreateHistory history={source.history} />
                                    </TabPanel>
                                </TabContext>

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => handleResetConfirm()} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose} sx={{ mr: 1 }}>Späť</Button>
                        <ButtonGroup disabled={loading} variant="contained" aria-label="Uložiť">
                            <Button disabled={props.disableSaveAction ?? false} type="button" variant="contained" size="small" aria-controls="save-menu" aria-haspopup="true" onClick={(e) => setSaveMenuEl(e.currentTarget)}>
                                <ArrowDropDownIcon />
                            </Button>
                            <Button type="submit" variant="contained">Uložiť</Button>
                        </ButtonGroup>
                    </DialogActions>
                </Box>

                <Menu id="save-menu" anchorEl={saveMenuEl} open={Boolean(saveMenuEl)} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'bottom', horizontal: 'left', }} onClose={() => setSaveMenuEl(null)}>
                    <MenuItem onClick={() => { handleSave(undefined, DocumentCreateSaveAction.SaveAndContinue); setSaveMenuEl(null); }}>
                        <ListItemIcon><SaveIcon fontSize="small" /></ListItemIcon> Uložiť a pokračovať
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleSave(undefined, DocumentCreateSaveAction.SaveAndNew); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> Uložiť a nový
                    </MenuItem>
                    <MenuItem onClick={() => { handleSave(undefined, DocumentCreateSaveAction.SaveAndNewFromCopy); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> Uložiť a nový z kópie
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleSave(undefined, DocumentCreateSaveAction.SaveAndPrint); setSaveMenuEl(null); }}>
                        <ListItemIcon><PrintIcon fontSize="small" /></ListItemIcon> Uložiť a vytlačiť
                    </MenuItem>
                </Menu>

                <Snackbar open={warning !== undefined} autoHideDuration={10000} onClose={() => setWarning(undefined)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                    <Alert sx={{ mt: 1 }} variant="filled" severity={'error'} action={
                        <IconButton color="inherit" size="small" onClick={() => setWarning(undefined)}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    >{warning}</Alert>
                </Snackbar>

            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Nový pohyb - pripojím len v prípade potreby */}
            {documentCreateMovement.open === true && (
                <DocumentCreateMovement {...documentCreateMovement}
                    documentType={props.documentType}
                    warehouseId={props.warehouseId}
                    warehouses={warehouses}
                    departmentId={props.departmentId}
                    departments={departments}
                    stockMovementTypes={stockMovementTypes} />
            )}
        </>
    )
}

export default DocumentCreate;